import { useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import { MANAGE_COLLECTIONS_API, NAV_COLLECTIONS_API } from "../../../../../Utilities/APIs/APIs"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import Categories from "../../CategoryScreens/Categories/Categories"
import { useEffect } from "react"



function CreateNavCollections({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [collection, setCollection] = useState('')
    const [precedence, setPrecedence] = useState('')
    
    const [collections, setCollections] = useState(null)
    // console.log("🚀 ~ collections:", collections)
    
    const axiosInstance = useAxiosInstance()

useEffect(() => {
    async function fetchAndSetItems() {
        const { data } = await axiosInstance.get(MANAGE_COLLECTIONS_API)
        setCollections(data)

    }
    fetchAndSetItems()
}, [])



    
    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = { collectionID: collection, precedence }

        const response = await axiosInstance.post(NAV_COLLECTIONS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
             <SelectInput value={collection} setState={setCollection}>
                <SelectOption optionValue={0} optionText={`Select Collection`} />
                {collections?.map(sItem => (
                    <SelectOption
                        key={sItem?._id}
                        optionValue={sItem?._id}
                        optionText={sItem?.name}
                    />
                ))}
            </SelectInput>
            <SelectInput
            label={"Precedence"}
            value={precedence}
            setState={setPrecedence}
          >
            <SelectOption optionValue="" optionText="Select Precedence" />
            <SelectOption optionValue="1" optionText="1" />
            <SelectOption optionValue="2" optionText="2" />
            <SelectOption optionValue="3" optionText="3" />
            <SelectOption optionValue="4" optionText="4" />
          </SelectInput>

            <FormSubmitButton text='Create Collections' />
        </Form>
    )
}

export default CreateNavCollections