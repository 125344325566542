import { useEffect, useState } from "react"
import { MANAGE_COLLECTIONS_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"

function UpdateCollections({ targetID, setShowUpdateForm, setShowModal, triggerFetch

}) {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSeCollectionInfo() {

            const { data } = await axiosInstance.get(MANAGE_COLLECTIONS_API + 'getSingleCollections/' + targetID)

            setName(data.name);
            setDescription(data.description);

        }
        fetchAndSeCollectionInfo()
    }, [axiosInstance, targetID])

    async function handleSubmit(e) {

        const itemData = { name, description }

        const response = await axiosInstance.patch(MANAGE_COLLECTIONS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label="Name"
                placeholder='Enter name'
                value={name}
                setState={setName}

            />
            <ShortTextInput
                label="Description"
                placeholder='Enter description'
                value={description}
                setState={setDescription}

            />


            <FormSubmitButton text='Update Collection' />
        </Form>
    )
}

export default UpdateCollections