import { useEffect, useState } from 'react'

import {  SUBCATEGORIES_API, ITEM_CATEGORIES_API, ITEM_SUBCATEGORIES_API, MANAGE_ITEM_API, MANAGE_CATEGORY_API } from '../../../../../Utilities/APIs/APIs'

import Form from '../../../../Partials/Layouts/Forms/Form'
import ImageInput from '../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput'
import LongTextInput from '../../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput'
import NumberInput from '../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput'
import RadioInput from '../../../../Partials/Layouts/Forms/FormInputs/RadioInput/RadioInput'
import RadioInputGroup from '../../../../Partials/Layouts/Forms/FormInputs/RadioInput/RadioInputGroup'
import SelectInput from '../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput'
import SelectOption from '../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption'
import ShortTextInput from '../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
import SwitchInput from '../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput'
import FormSubmitButton from '../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'

function UpdateItem({ employee, targetID, setShowUpdateForm, setShowModal }) {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState(0)
    const [isAvailable, setIsAvailable] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [image, setImage] = useState(null)

    const [categoryOrSubcategory, setCategoryOrSubcategory] = useState('category')
    const [category, setCategory] = useState('')
    const [subcategory, setSubcategory] = useState('')

    const [allCategories, setAllCategories] = useState(null)
    const [allSubCategories, setAllSubCategories] = useState(null)
    const [subcategoriesInCategory, setSubcategoriesInCategory] = useState(null)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndUpdateItem() {

            const { data } = await axiosInstance.get(MANAGE_ITEM_API + 'getSingleItem/' + targetID)
            setName(data?.name)
            setDescription(data?.description)
            setPrice(data?.price)
            setIsAvailable(data?.isAvailable)
            setIsVisible(data?.isVisible)
            setImage(data?.image)
        }
        fetchAndUpdateItem()

        async function fetchAndSetCategories() {
            const { data } = await axiosInstance.get(MANAGE_CATEGORY_API)
            setAllCategories(data)
        }

        fetchAndSetCategories()

        async function fetchAndSetSubcategories() {
            const { data } = await axiosInstance.get(SUBCATEGORIES_API)
            setAllSubCategories(data)
        }

        fetchAndSetSubcategories()
    }, [axiosInstance, targetID])

    useEffect(() => {
        if (allSubCategories && categoryOrSubcategory === 'subcategory') {
            const subcatsInCats = allSubCategories.filter(subCat => subCat.category === category)
            setSubcategoriesInCategory(subcatsInCats)
        }
    }, [category, allSubCategories, categoryOrSubcategory])

    useEffect(() => {

        async function fetchAndSetItemToUpdate() {
            const { data } = await axiosInstance.get(MANAGE_ITEM_API + targetID)
            setName(data.name)
            setDescription(data.description)
            setPrice(data.price)
            setIsAvailable(data.isAvailable)
            setIsVisible(data.isVisible)
            setCategoryOrSubcategory(data.categoryOrSubcategory)
            setImage(data.image)
        }

        fetchAndSetItemToUpdate()

        

    }, [targetID, axiosInstance])

    useEffect(() => {
        if (categoryOrSubcategory && categoryOrSubcategory === 'category') {
            async function fetchAndSetItemCategory() {
                const {data} = await axiosInstance.get(ITEM_CATEGORIES_API)
                const itemCategory = data.find(d => d.item === targetID)?.category
                setCategory(itemCategory)
            }
            fetchAndSetItemCategory()
        }

        if (categoryOrSubcategory && allSubCategories && categoryOrSubcategory === 'subcategory') {
            async function fetchAndSetItemSubcategory() {
                const {data} = await axiosInstance.get(ITEM_SUBCATEGORIES_API)
                const itemSubcategory = data.find(d => d.item === targetID)?.subcategory
                const itemCategory = allSubCategories.find(s => s._id === itemSubcategory)?.category
                setCategory(itemCategory)
                setSubcategory(itemSubcategory)
            }
            fetchAndSetItemSubcategory()
        }
    }, [targetID, categoryOrSubcategory, allSubCategories, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('price', price)
        itemData.append('isAvailable', isAvailable)
        itemData.append('isVisible', isVisible)
        itemData.append('image', image)
        itemData.append('categoryOrSubcategory', categoryOrSubcategory)
        if (categoryOrSubcategory === 'category') {
            itemData.append('category', category)
        }
        if (categoryOrSubcategory === 'subcategory') {
            itemData.append('subcategory', subcategory)
        }
        console.log(itemData)

        const response = await axiosInstance.patch(MANAGE_ITEM_API + targetID, itemData, config)

        if (response) {
            setName('')
            setDescription('')
            setPrice(0)
            setIsAvailable(false)
            setIsVisible(false)
            setImage(null)

            setCategoryOrSubcategory('category')
            setCategory('')
            setSubcategory('')


            setShowUpdateForm(false);
            setShowModal(false);
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`Item Name`}
                value={name}
                placeholder={`Enter Item Name`}
                setState={setName}
            />
            <LongTextInput
                label={`Item Description`}
                value={description}
                placeholder={`Enter Item Description`}
                setState={setDescription}
            />
            <NumberInput
                label={`Item Price`}
                value={price}
                placeholder={`Enter Item Price`}
                setState={setPrice}
            />
            <SwitchInput
                label={'Is Available'}
                toggleSwitch={() => setIsAvailable(prevState => !prevState)}
                checked={isAvailable}
            />

            <SwitchInput
                label={'Is Visible'}
                toggleSwitch={() => setIsVisible(prevState => !prevState)}
                checked={isVisible}
            />

            <ImageInput state={image} setState={setImage} allowUpdateImage={true}>Upload Image</ImageInput>

            <RadioInputGroup radioGroupLabel={'Category or Subcategory'}>
                <RadioInput
                    label={'Category'}
                    value={'category'}
                    state={categoryOrSubcategory}
                    setState={setCategoryOrSubcategory}
                    />
                <RadioInput
                    label={'Subcategory'}
                    value={'subcategory'}
                    state={categoryOrSubcategory}
                    setState={setCategoryOrSubcategory}
                />
            </RadioInputGroup>
            <SelectInput value={category} setState={setCategory}>
                <SelectOption optionValue={''} optionText={`Select Category`} hidden />
                {allCategories && allCategories.map(cat => (
                    <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
                ))}
            </SelectInput>
            {
                (category?.length > 0) && (categoryOrSubcategory === 'subcategory') &&
                <SelectInput value={subcategory} setState={setSubcategory}>
                    <SelectOption optionValue={''} optionText={`Select Subcategory`} hidden />
                    {subcategoriesInCategory && subcategoriesInCategory.map(subCat => (
                        <SelectOption key={subCat._id} optionValue={subCat._id} optionText={subCat.name} />
                    ))}
                </SelectInput>
            }
            <FormSubmitButton text='Update Item' />
        </Form>
    )
}

export default UpdateItem