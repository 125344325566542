import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewTeamDetail({ targetID }) {
  const [teamMember, setTeamMember] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        HOME_API + "ourTeam/getSingleTeamMember/" + targetID
      );
      console.log("team12345", data);
      setTeamMember(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {teamMember && (
        <>
          <Image imgLink={teamMember.image} imgAlt={"Team Image"} />

          <h1>Name</h1>
          <p>{teamMember?.name}</p>

          <h1>Description</h1>
          <p>{teamMember?.description}</p>

          <h1>Designation</h1>
          <p>{teamMember?.designation}</p>
        </>
      )}
    </div>
  );
}

export default ViewTeamDetail;
