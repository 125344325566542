import {
  RiBuildingLine,
  RiSettings4Line,
  RiBriefcase2Line,
  RiShoppingCart2Line,
  RiMapPin2Line,
  RiUser2Line,
  RiGiftLine,
  RiRhythmLine,
} from "react-icons/ri";
import { IoHome } from "react-icons/io5";
import { GrContactInfo, GrGallery } from "react-icons/gr";
import { FaBlog } from "react-icons/fa";
import { MdEmojiEvents } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { RiUserShared2Line } from "react-icons/ri";

import SmallSideBarItem from "./SmallSideBarItem/SmallSideBarItem";
import { FaAward } from "react-icons/fa";

import "./SideBar.css";
import { useContext } from "react";
import AppContext from "../../../../AppContext/AppContext";
import { BsJournal } from "react-icons/bs";

function SideBar() {
  const { employee } = useContext(AppContext);

  console.log("employee12345 ", employee);

  return (
    <>
      {employee.level === "employee" ? (
        <section className="small_sidebar">
          <div className="container">
            <SmallSideBarItem>
              <RiBuildingLine />
            </SmallSideBarItem>
            <SmallSideBarItem link={"/home"} dataTip="Home Page" dataFor="home">
              <IoHome />
            </SmallSideBarItem>
            <SmallSideBarItem link={"/about"} dataTip="About" dataFor="about">
              <RiBriefcase2Line />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/gallery_content"}
              dataTip="Gallery"
              dataFor="gallery_content"
            >
              <GrGallery />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/journal"}
              dataTip="Journal"
              dataFor="Journal"
            >
              <BsJournal />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/events"}
              dataTip="Events"
              dataFor="events"
            >
              <MdEmojiEvents />
            </SmallSideBarItem>
            <SmallSideBarItem link={"/blogs"} dataTip="Blogs" dataFor="blogs">
              <FaBlog />
            </SmallSideBarItem>
            {/* <SmallSideBarItem link={'/divisions'}>
                        <RiMapPin2Line />
                    </SmallSideBarItem> */}
            <SmallSideBarItem
              link={"/contact"}
              dataTip="Contact"
              dataFor="contact"
            >
              <IoLocationOutline />
            </SmallSideBarItem>
          </div>
        </section>
      ) : (
        <section className="small_sidebar">
          <div className="container">
            <SmallSideBarItem>
              <RiBuildingLine />
            </SmallSideBarItem>
            <SmallSideBarItem link={"/home"} dataTip="Home" dataFor="home">
              <IoHome />
            </SmallSideBarItem>
            <SmallSideBarItem link={"/about"} dataTip="About" dataFor="about">
              <RiBriefcase2Line />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/gallery_content"}
              dataTip="Gallery"
              dataFor="gallery_content"
            >
              <GrGallery />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/journal"}
              dataTip="Journal"
              dataFor="journal"
            >
              <BsJournal />
            </SmallSideBarItem>

            <SmallSideBarItem
              link={"/events"}
              dataTip="Events"
              dataFor="events"
            >
              {/* <SmallSideBarItem link={"/events"}> */}
              <MdEmojiEvents />
            </SmallSideBarItem>
            {/* <SmallSideBarItem link={"/APBC_Events"}>
              <FaAward />
            </SmallSideBarItem> */}
            <SmallSideBarItem link={"/blogs"} dataTip="Blogs" dataFor="blogs">
              <FaBlog />
            </SmallSideBarItem>
            {/* <SmallSideBarItem link={"/paymentDetails"}>
              <FaBlog />
            </SmallSideBarItem> */}
            <SmallSideBarItem
              link={"/contact"}
              dataTip="Contact"
              dataFor="contact"
            >
              <GrContactInfo />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/employee-invites"}
              dataTip="Employee Invites"
              dataFor="employee-invites"
            >
              <RiUserShared2Line />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/employees"}
              dataTip="Employees"
              dataFor="employees"
            >
              <RiUser2Line />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/customerScreens"}
              dataTip="Customer Screens"
              dataFor="customerScreens"
            >
              <FaUsers />
            </SmallSideBarItem>
            <SmallSideBarItem
              link={"/general_settings"}
              dataTip="General Settings"
              dataFor="general_settings"
            >
              <RiSettings4Line />
            </SmallSideBarItem>
          </div>
        </section>
      )}
    </>
  );
}

export default SideBar;
