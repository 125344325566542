import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import "./ApproveItem.css";

function APBCApproveItem({
  api,
  targetID,
  setShowDeleteSection,
  setShowModal,
  triggerFetch,
  email,
}) {
  const axiosInstance = useAxiosInstance();

  async function handleDelete(id, data) {
    const res = await axiosInstance.patch(api + id, {
      isApprove: data,
      email,
    });

    if (res.data) {
      setShowModal(false);
      setShowDeleteSection(false);
      triggerFetch();
    }
  }

  return (
    <div className="delete_item">
      <div className="approve_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to Approve this Ticket.</p>
        <div className="approve_container">
          <button
            onClick={() => handleDelete(targetID, "Approve")}
            className="approve"
          >
            Approve
          </button>
          <button
            onClick={() => handleDelete(targetID, "Rejected")}
            className="approve"
          >
            Rejected
          </button>
        </div>
      </div>
    </div>
  );
}

export default APBCApproveItem;
