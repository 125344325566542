import {  ITEM_ASSETS_API, MANAGE_ITEM_API } from '../../../../Utilities/APIs/APIs'
import IPSCRUD from '../../../Partials/Layouts/CRUDs/CRUDLayouts/IPSCRUD/IPSCRUD'

function ItemAssets() {
  return (
    <IPSCRUD
        // common props
        api={ITEM_ASSETS_API}
        screenTopicSingular={'Item Asset'}
        screenTopicPlural={'Item Assets'}
        
        // crud header props
        showTotalInHeading
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete
        
        // field props
        maxPrecedence={4}
        
        // select props
        selectApi={MANAGE_ITEM_API}
        selectType={'parent-child'}
        selectDBField={'item'}
        selectName={'Item'}
    />
  )
}

export default ItemAssets