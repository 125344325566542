import { useEffect, useState } from "react";
import {
  GALLERY_API,
  GALLERY_IMAGE_API,
} from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";

function UpdateGalleryImage({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [image, setImage] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(
        GALLERY_API + "galleryImage/" + targetID
      );
      console.log(data.precedence);
      setPrecedence(data.precedence);
      setImage(data.image);
    }
    fetchAndSetProductInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);
    itemData.append("precedence", precedence);

    const response = await axiosInstance.patch(
      GALLERY_API + "galleryImage/" + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        fieldId="2"
        value={precedence}
        state={precedence}
        setState={setPrecedence}
        placeholder="precedence"
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Update Card Image
      </ImageInput>
      <FormSubmitButton text="Update Gallery Image" />
    </Form>
  );
}

export default UpdateGalleryImage;
