import useFetchSingleJournal from "../Hooks/useFetchSingleJournal";

function ViewJournal({ targetID }) {
  const journal = useFetchSingleJournal({ targetID });

  return (
    <div className="crud_view_content">
      {journal && (
        <>
          <h1>Title</h1>
          {journal?.title}

          <h1>Dio Link</h1>
          <a href={journal?.dioLink} style={{ marginBottom: "20px" }}>
            {journal?.dioLink}
          </a>
          <h1>Publication Date</h1>
          <p>{new Date(journal?.createdAt).toDateString()}</p>
          <h1>Attachment</h1>
          <iframe
            src={process.env.REACT_APP_SPACES_URL + journal?.pdfLink}
            width="100%"
            height={700}
            title="pdf"
            frameborder="0"
          ></iframe>
          <h1>Abstract</h1>
          <div
            style={{ marginBottom: "12px" }}
            dangerouslySetInnerHTML={{ __html: journal?.abstract }}
          />
        </>
      )}
    </div>
  );
}

export default ViewJournal;
