import { RiChatQuoteFill, RiFacebookBoxFill, RiImage2Fill, RiPercentFill, RiPriceTag2Fill, RiSettings2Fill, RiTruckFill } from "react-icons/ri";

import ScreenHolder from "../../Partials/Layouts/ScreenHolder/ScreenHolder";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";

function GeneralSettings() {
  return (
    <ScreenHolder>
      <NavCardList numOfCards={'seven'}>
        <NavCard cardName={'Social Links'} navCardLink='/social_links' ><RiFacebookBoxFill /></NavCard>
        {/* <NavCard cardName={'Testimonials'} navCardLink='/testimonials' ><RiChatQuoteFill /></NavCard> */}
        <NavCard cardName={'App Settings'} navCardLink='/app_settings' ><RiSettings2Fill /></NavCard>
      </NavCardList>
    </ScreenHolder>
  )
}

export default GeneralSettings