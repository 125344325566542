import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import { NAV_COLLECTIONS_API } from "../../../../../Utilities/APIs/APIs"


function ViewNavCollections({ targetID, employee }) {

    const [collection, setCollection] = useState(null)
    console.log("🚀 ~ ViewCollections ~ category:", collection)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetCollections() {

            const { data } = await axiosInstance.get(NAV_COLLECTIONS_API + 'getSingleNavCollections/' + targetID)
            setCollection(data);
        }
        fetchAndSetCollections()
    }, [axiosInstance, targetID])

    return (
        <div className="crud_view_content">

            {collection &&
                <>

                    <h1>Collection ID</h1>
                    <p>{collection?.collectionID}</p>

                    <h1>Precedence</h1>
                    <p>{collection?.precedence}</p>


                </>
            }
        </div>
    )
}

export default ViewNavCollections