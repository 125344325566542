import { useEffect, useState } from "react"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import { MANAGE_CATEGORY_API } from "../../../../../Utilities/APIs/APIs"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"




function UpdateCategory({targetID, setShowUpdateForm, setShowModal, triggerFetch

}) {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetSliderInfo() {

            const { data } = await axiosInstance.get(MANAGE_CATEGORY_API)

            setName(data.link);
            setDescription(data.image);
            
        }
        fetchAndSetSliderInfo()
    }, [ axiosInstance])

    async function handleSubmit(e) {

    const  itemData = {name, description}

        const response = await axiosInstance.patch(MANAGE_CATEGORY_API + targetID ,itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label= "Name"
                placeholder='Enter name'
                value={name}
                setState={setName}

            />
            <ShortTextInput
                label= "Description"
                placeholder='Enter description'
                value={description}
                setState={setDescription}

            />


            <FormSubmitButton text='Update Category' />
        </Form>
    )
}

export default UpdateCategory