import { ITEM_CATEGORIES_API, MANAGE_CATEGORY_API, MANAGE_ITEM_API } from "../../../../Utilities/APIs/APIs"
import PcSSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/SSCRUD/PcSSCRUD"

function ItemCategories() {
  return (
    <PcSSCRUD

        // parentChild props
        parentDBField='category'

        // common props
        api={ITEM_CATEGORIES_API}
        screenTopicSingular='Item Category'
        screenTopicPlural='Item Categories'

        // crud header props
        showTotalInHeading

        // select props
        selectType='parent-child'
        selectApi={MANAGE_CATEGORY_API}
        selectDBField='category'
        selectName='Category'

        // secondSelect props
        secondSelectApi={MANAGE_ITEM_API}
        secondSelectName='Item'
        secondSelectDBField='item'
    />
  )
}

export default ItemCategories