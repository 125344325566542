import { useEffect, useState } from "react";

import { MdOutlineFileUpload } from "react-icons/md";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API, about_API } from "../../../../../Utilities/APIs/APIs";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import LongTextInput from "../../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
function CreateQNA_section({
  employee,
  targetID,
  setShowCreateForm,
  setShowModal,
}) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  //   const [image, setImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("question", question);
    itemData.append("answer", answer);
    // itemData.append("image", image);

    const response = await axiosInstance.post(
      about_API + "QNA_section/",
      itemData,
      config
    );

    console.log(response);

    if (response.status === 200 || response.status === 201) {
      setQuestion("");
      setAnswer("");
      //   setImage(null);

      setShowCreateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`question`}
        value={question}
        placeholder={`Enter Title`}
        setState={setQuestion}
      />

      <LongTextInput
        label={`Answer`}
        value={answer}
        placeholder={`Enter Answer`}
        setState={setAnswer}
      />

      {/* <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowUpAnswerImage
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput> */}

      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateQNA_section;
