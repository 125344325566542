import "./ShortTextCell.css";

function ShortTextCell({ text }) {
  return (
    <td
      title={text}
      style={{
        maxWidth: "250px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        // textAlign: "center",
      }}
    >
      {text}
    </td>
  );
}

export default ShortTextCell;
