import { useState } from "react";
import { GALLERY_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreteGallery({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  storeId,
}) {
  const axiosInstance = useAxiosInstance();

  const [title, setTitle] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [description, setDescription] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("cardImage", cardImage);
    itemData.append("precedence", precedence);
    itemData.append("description", description);

    const response = await axiosInstance.post(GALLERY_API, itemData);
    // console.log(response);
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  console.log("image is", cardImage);
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />

      <ImageInput
        fieldId="1"
        state={cardImage}
        setState={setCardImage}
        allowCreateImage
      >
        Upload Card Image
      </ImageInput>
      <FormSubmitButton text="Create Image" />
    </Form>
  );
}

export default CreteGallery;
