import { RiMapPin2Fill } from "react-icons/ri";

import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { useContext, useState } from "react";
import AppContext from "../../../AppContext/AppContext";
import Modal from "../../Partials/Elements/Modal/Modal";
import ContactContentForm from "./ContactContent/ContactContentForm";
// import HeroUpdateForm from "./HomeForms/HeroUpdateForm";
// import ConferenceAndMembershipForm from "./HomeForms/ConferenceAndMembershipForm";

function Contact() {
  const [showContactContentForm, setShowContactContentForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { employee } = useContext(AppContext);

  const closeModal = () => {
    setShowContactContentForm(false);
    setShowModal(false);
  };
  return (
    <>
      <NavCardList numOfCards={"two"}>
        <NavCard
          cardName={"Contact Content"}
          navCardLink="#"
          onClick={() => {
            setShowContactContentForm(true);
            setShowModal(true);
          }}
        >
          <RiMapPin2Fill />
        </NavCard>

        <NavCard cardName={"Contact Message"} navCardLink="/contact_message">
          <RiMapPin2Fill />
        </NavCard>
      </NavCardList>
      {showModal && (
        <Modal
          handleClose={closeModal}
          modalHeading={"Update Contact Page Content"}
        >
          {showContactContentForm && (
            <ContactContentForm
              employee={employee}
              targetID={"665588058b5f3423a694c367"}
              setShowUpdateForm={setShowContactContentForm}
              setShowModal={setShowModal}
              // triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default Contact;
