import { useEffect, useState } from "react";
import {
  APBC_Events_API,
  EVENTS_HOTEL_API,
  WEBSITE_CONTENT_API,
} from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewHotel({ targetID }) {
  const [hotelInfo, setHotelInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetGalleryIngo() {
      const { data } = await axiosInstance.get(
        APBC_Events_API + "nearbyhotels/" + targetID
      );
      setHotelInfo(data);
    }
    fetchAndsetGalleryIngo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {hotelInfo && (
        <>
          <h1>name</h1>
          <p>{hotelInfo?.name}</p>

          <h1>Link</h1>
          <p>{hotelInfo?.locationLink}</p>
        </>
      )}
    </div>
  );
}

export default ViewHotel;
