import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { FEATURED_CATEGORIES_API, MANAGE_CATEGORY_API } from "../../../../../Utilities/APIs/APIs";
import Form from "../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";



function UpdateFeaturedCategories({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {
    const [category, setCategory] = useState("");
    const [precedence, setPrecedence] = useState("");
    
   const [categories, setCategories] = useState(null)

    const axiosInstance = useAxiosInstance()
    async function fetchAndSetCategories() {
        const { data } = await axiosInstance.get(FEATURED_CATEGORIES_API)
        setCategory(data?.category)
        setPrecedence(data?.precedence)
    }
    fetchAndSetCategories()

    
     useEffect(() => {
         async function fetchAndSetCategories() {
             const { data } = await axiosInstance.get(MANAGE_CATEGORY_API)
             setCategories(data)
         }
         fetchAndSetCategories()
 
     }, [axiosInstance])


    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = { category, precedence }
console.log(itemData,'erewr');
        const response = await axiosInstance.patch(FEATURED_CATEGORIES_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <SelectInput value={category} setState={setCategory}>
                <SelectOption optionValue={0} optionText={`Select Categories`} />
                {categories?.map(sItem => (
                    <SelectOption
                        key={sItem._id}
                        optionValue={sItem._id}
                        optionText={sItem.name}
                    />
                ))}
            </SelectInput>
            <SelectInput
                label={"Precedence"}
                value={precedence}
                setState={setPrecedence}
            >
                <SelectOption optionValue="" optionText="Select Precedence" />
                <SelectOption optionValue="1" optionText="1" />
                <SelectOption optionValue="2" optionText="2" />
                <SelectOption optionValue="3" optionText="3" />
                <SelectOption optionValue="4" optionText="4" />
            </SelectInput>

            <FormSubmitButton text='Create  Featured Categories' />
        </Form>
    )
}

export default  UpdateFeaturedCategories