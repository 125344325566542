import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { about_API } from "../../../../../Utilities/APIs/APIs";
import Image from "../../../../Partials/Elements/Image/Image";

function ViewQNA_section({ targetID }) {
  const [details, setDetails] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        about_API + "QNA_section/getSinglePresidentPossession/" + targetID
      );
      console.log("team12345", data);
      setDetails(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {details && (
        <>
          <h1>Question</h1>
          <p>{details?.question}</p>

          <h1>Answer</h1>
          <p>{details?.answer}</p>
        </>
      )}
    </div>
  );
}

export default ViewQNA_section;
