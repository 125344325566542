import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { FEATURED_ITEMS_API, about_API } from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CreateHistoryOfSPSB from "./Forms/CreateHistoryOfSPSB";
import UpdateHistoryOfSPSB from "./Forms/UpdateHistoryOfSPSB";
import ViewHistoryOfSPSB from "./Forms/ViewHistoryOfSPSB";

function HistoryOfSPSB() {
  const [cotentItem, setCotentItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCotentItem() {
      const { data } = await axiosInstance.get(about_API + "history_of_SPSB");
      setCotentItem(data);
    }
    fetchAndSetCotentItem();
  }, [toggleFetch, axiosInstance, showModal]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`History_of_SPSB Items (${cotentItem?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular="Item"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Title" />
            <CRUDth th="Description" />
            <CRUDth th="Button Text" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {cotentItem?.map((item) => (
              <CRUDTableRow key={item._id}>
                <ShortTextCell text={item?.title} />
                <div>
                  <ShortTextCell text={item?.description} />
                </div>
                <ShortTextCell text={item?.buttonText} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={item?._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={item?._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={item?._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create History Of SPSB Item`) ||
            (showUpdateForm && `Update History Of SPSB Item`) ||
            (showDeleteSection && `Delete History Of SPSB Item`)
          }
        >
          {showCreateForm && (
            <CreateHistoryOfSPSB
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateHistoryOfSPSB
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={about_API + "history_of_SPSB/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View History Of SPSB`}
        >
          <ViewHistoryOfSPSB targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default HistoryOfSPSB;
