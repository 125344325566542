import React from 'react';
import ReactDOM from 'react-dom/client';
// import { store } from './Redux/store'
// import { Provider } from 'react-redux'

import './index.css';

import App from './Components/App';
import { AppContextProvider } from './AppContext/AppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>
);

