import { useEffect, useState } from "react";
import { SUBCATEGORIES_API } from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";

function ViewSubcategory({ targetID }) {
  const [subcategory, setSubcateroy] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(SUBCATEGORIES_API + targetID);
      setSubcateroy(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {subcategory && (
        <>
          <h1>Name</h1>
          <p>{subcategory?.name}</p>

          <h1>Description</h1>
          <p>{subcategory?.description}</p>

          <h1>Precedence</h1>
          <p>{subcategory?.precedence}</p>
        </>
      )}
    </div>
  );
}

export default ViewSubcategory;
