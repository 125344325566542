import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { GALLERY_API } from "../../../../Utilities/APIs/APIs";

function UpdateGallery({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [description, setDescription] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(
        GALLERY_API + "getSingleGallery/" + targetID
      );
      setTitle(data.title);
      setPrecedence(data.precedence);
      setCardImage(data.cardImage);
      setDescription(data.description);
    }
    fetchAndSetProductInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("cardImage", cardImage);
    itemData.append("precedence", precedence);
    itemData.append("description", description);

    const response = await axiosInstance.patch(
      GALLERY_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />
      <ImageInput
        fieldId="1"
        state={cardImage}
        setState={setCardImage}
        allowUpdateImage
      >
        Upload Card Image
      </ImageInput>
      <FormSubmitButton text="Update Gallery Image" />
    </Form>
  );
}

export default UpdateGallery;
