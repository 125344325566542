import { useContext, useEffect, useState } from "react"
import { RiCheckboxCircleLine, RiCloseCircleLine, RiGiftLine, RiListSettingsLine, RiRepeatLine, RiTruckLine } from "react-icons/ri"

import { useNavigate, useParams } from "react-router-dom"
import { CHANGE_ORDERS_STATUS_API, ORDERS_API } from "../../../../Utilities/APIs/APIs"
import Modal from "../../../Partials/Elements/Modal/Modal"
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ViewOrder from "../ViewOrder"
import OrderDelivered from "./OrderDelivered"
import CancelOrder from "./CancelOrder"
import { takaFormatter } from "../../../../Utilities/Formatter"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import AppContext from "../../../../AppContext/AppContext"

function OrdersFromStatus() {

    const [orders, setOrders] = useState(null)
    // console.log(orders)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showProcessingOrder, setShowProcessingOrder] = useState(false)
    const [showCancelOrder, setShowCancelOrder] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)

    const {employee} = useContext(AppContext)

    console.log(employee.token)
    const { status } = useParams()
    const navigate = useNavigate()

    // console.log(status)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetOrders() {
            const { data } = await axiosInstance.get(ORDERS_API + 'allOrdersByStatus/' + status)
            setOrders(data)
        }
        fetchAndSetOrders()

    }, [ status, toggleFetch, axiosInstance])

    function handleClose() {
        setShowModal(false);
        setShowViewSection(false);
        setShowProcessingOrder(false)
    }

    function buttonIcon() {
        switch (status) {
            case 'processing':
                return <RiGiftLine />

            case 'toBeDelivered':
                return <RiTruckLine />

            case 'shipped':
                return <RiCheckboxCircleLine />

            default:
                return <RiRepeatLine />
        }
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }

    async function handleStatusChange(id) {

    

        switch (status) {
            case 'processing':
                await axiosInstance.patch(CHANGE_ORDERS_STATUS_API + 'toBeDeliveredFromProcessing/' + id, '', )
                triggerFetch()
                handleClose()

                return

            case 'toBeDelivered':
                await axiosInstance.patch(CHANGE_ORDERS_STATUS_API + 'shippedFromToBeDelivered/' + id, '', )
                triggerFetch()
                handleClose()

                return

            case 'shipped':
                await axiosInstance.patch(CHANGE_ORDERS_STATUS_API + 'completedFromShipped/' + id, '', )
                triggerFetch()
                handleClose()

                return

            default:
                return console.log('Status Not Changed')
        }




    }



    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`${status?.toUpperCase()} Orders (${orders?.length} in total)`}</h1>
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Order ID' />
                        <CRUDth th='Status' />
                        <CRUDth th='Total' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {orders?.map(order => (
                            <CRUDTableRow key={order._id}>
                                <ShortTextCell text={order._id} />
                                <ShortTextCell text={order.orderStatus} />
                                <ShortTextCell text={takaFormatter(order.grandTotal)} />
                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={order._id}
                                        setTargetID={setTargetID}
                                    />
                                    <CRUDButton handleClick={() => navigate('/order_items_from_order/' + order._id)}>
                                        <RiListSettingsLine />
                                    </CRUDButton>
                                    {
                                        (status === 'processing' || status === 'toBeDelivered' || status === 'shipped') &&

                                        <CRUDButton
                                            handleClick={() => {
                                                setShowModal(true)
                                                setShowProcessingOrder(true)
                                                setTargetID(order._id)

                                            }}
                                        >
                                            {buttonIcon()}
                                        </CRUDButton>
                                    }
                                    {
                                        (status !== 'completed' && status !== 'canceled') &&
                                        <CRUDButton
                                            handleClick={() => {
                                                setShowModal(true)
                                                setShowCancelOrder(true)
                                                setTargetID(order._id)

                                            }}
                                        >
                                            <RiCloseCircleLine />
                                        </CRUDButton>
                                    }

                                    {/* {
                                        (status === 'processing' || status === 'toBeDelivered' || status === 'shipped') &&
                                        <CRUDButton handleClick={() => handleStatusChange(order._id)}>
                                            {buttonIcon()}
                                        </CRUDButton>
                                    } */}

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Order`)
                    }
                >
                    {
                        showViewSection &&
                        <ViewOrder
                            targetID={targetID}
                            employee={employee}
                        />
                    }
                </Modal>
            }

            {showModal && showProcessingOrder &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showProcessingOrder && `Precessing Order`)
                    }
                >

                    <OrderDelivered
                        handleStatusChange={handleStatusChange}
                        targetID={targetID}
                        setShowModal={setShowModal}
                        setShowProcessingOrder={setShowProcessingOrder}
                    />


                </Modal>
            }
            {showModal && showCancelOrder &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showCancelOrder && `Cancel Order`)
                    }
                >

                    <CancelOrder
                        targetID={targetID}
                        employee={employee}
                        setShowModal={setShowModal}
                        setShowCancelOrder={setShowCancelOrder}
                        triggerFetch={triggerFetch}
                    />


                </Modal>
            }
        </>
    )
}

export default OrdersFromStatus