import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { BLOGS, HOME_API } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewBlogPostComment({ targetID }) {
  const [content, setContent] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        BLOGS + "getSingleBlogComment/" + targetID
      );
      console.log("team12345", data);
      setContent(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {content && (
        <>
          <h1>Name</h1>
          <p>{content?.name}</p>

          <h1>Email</h1>
          <p>{content?.email}</p>

          <h1>Message</h1>
          <p>{content?.content}</p>
        </>
      )}
    </div>
  );
}

export default ViewBlogPostComment;
