import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
function UpdateBenefitsForm({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [benefitsLists, setBenefitsLists] = useState([]);
  const [benefitText, setbenefitText] = useState([]);
  const [image, setImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        HOME_API + "benefits/getSingleBenefit/" + targetID
      );
      console.log("hero data12345:", data);
      setTitle(data.title);
      setDescription(data.description);
      setBenefitsLists(data.benefitsLists);
      setImage(data.icon);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("benefitsLists", benefitsLists.join(","));
    itemData.append("image", image);

    console.log(benefitsLists.join(","));

    const response = await axiosInstance.patch(
      HOME_API + "benefits/" + targetID,
      itemData,
      config
    );

    console.log(response);

    if (response.status === 200 || response.status === 201) {
      setTitle("");
      setDescription("");
      setImage(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  const handleBenefitList = () => {
    setBenefitsLists([...benefitsLists, benefitText]);
    setbenefitText("");
  };

  const handleTextChange = (e) => {
    setbenefitText(e.target.value);

    if (e.key === "Enter") {
      handleBenefitList();
    }
  };

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <LongTextInput
        label={`Value`}
        value={description}
        placeholder={`Enter description`}
        setState={setDescription}
      />

      <ol
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          marginBottom: "20px",
          fontWeight: "bold",
          color: "#000",
          width: "100%",
          padding: "3px 10px",
        }}
      >
        {benefitsLists?.map((item, index) => (
          <li key={index}>
            {index + 1}. {item}
          </li>
        ))}
      </ol>

      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "5px",
          justifyContent: "space-between",
          height: "38px",
          marginBottom: "10px",
        }}
      >
        <input
          type="text"
          value={benefitText}
          onChange={handleTextChange}
          style={{ width: "75%" }}
        />
        <div
          onClick={handleBenefitList}
          style={{
            width: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "gray",
            color: "white",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Add
        </div>
      </div>

      <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowUpdateImage={true}
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput>

      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateBenefitsForm;
