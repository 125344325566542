import { useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import { MANAGE_CATEGORY_API } from "../../../../../Utilities/APIs/APIs"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"


function CreateCategory({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

   const [name, setName] = useState('')
   const [description, setDescription] = useState('')
    const [image, setImage] = useState()
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('image', image)
    

        const response = await axiosInstance.post(MANAGE_CATEGORY_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label= "Name"
                placeholder='Enter name'
                value={name}
                setState={setName}

            />
            <ShortTextInput
                label= "Description"
                placeholder='Enter description'
                value={description}
                setState={setDescription}

            />
            <ImageInput setState={setImage} allowCreateImage={true}>Upload Image</ImageInput>


            <FormSubmitButton text='Create Category' />
        </Form>
    )
}

export default CreateCategory