import React, { useState } from "react";
import { TiDelete } from "react-icons/ti";

function AddListItem({ list, setList }) {
  const [text, setText] = useState([]);

  const handleList = () => {
    if (text !== "") {
      setList([...list, text]);
      console.log("text", text, list);
      setText("");
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.value);

    if (e.key === "Enter") {
      handleList();
    }
  };

  const deleteItem = (id) => {
    console.log("id", id);
    const filterArray = list.filter((item) => item !== id);
    setList(filterArray);
  };
  return (
    <>
      <h4 style={{ color: "#666", marginBottom: "10px" }}>Add list</h4>

      <ol
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          marginBottom: "20px",
          fontWeight: "bold",
          color: "#666",
          width: "100%",
          padding: "3px 10px",
        }}
      >
        {list?.map((item, index) => (
          <li
            key={index}
            style={{
              position: "relative",
              padding: "3px",
              border: "1px solid #666",
              borderRadius: "5px",
            }}
          >
            {index + 1}. {item}
            <button
              type="button"
              onClick={() => deleteItem(item)}
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                transform: "translate(50%, -50%)",
                color:"red"
              }}
            >
              <TiDelete />
            </button>
          </li>
        ))}
      </ol>

      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "5px",
          justifyContent: "space-between",
          height: "38px",
          marginBottom: "10px",
        }}
      >
        <input
          type="text"
          value={text}
          onChange={handleTextChange}
          style={{ width: "75%", padding: "4px 8px" }}
        />
        <div
          onClick={handleList}
          style={{
            width: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "gray",
            color: "white",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Add
        </div>
      </div>
    </>
  );
}

export default AddListItem;
