import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  ALL_EVENTS_API,
  EVENTS_VISIT_API,
  WEBSITE_CONTENT_API,
} from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewVisit({ targetID }) {
  console.log(targetID, "id");
  const [visitInfo, setVisitInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetVisitInfo() {
      const { data } = await axiosInstance.get(
        ALL_EVENTS_API + "visit/" + targetID
      );
      console.log(data, "data");
      setVisitInfo(data);
    }
    fetchAndsetVisitInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {visitInfo && (
        <>
          <h1>Name</h1>
          <p>{visitInfo?.name}</p>

          {/* <h1>Description</h1>
          <p>{eventInfo?.shortDescription}</p> */}

          <h1>Card Image</h1>
          <Image imgLink={visitInfo?.image} imgAlt={"Card Front Image"} />
        </>
      )}
    </div>
  );
}

export default ViewVisit;
