import { useEffect, useState } from "react";

import { MdOutlineFileUpload } from "react-icons/md";
import { HOME_API, about_API } from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import LongTextInput from "../../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
function QNA_section({ employee, targetID, setShowUpdateForm, setShowModal }) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  //   const [image, setImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        about_API + "QNA_section/getSinglePresidentPossession/" + targetID
      );
      console.log("hero data12345:", data);

      setQuestion(data?.question);
      setAnswer(data?.answer);
      //   setImage(data.image);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("question", question);
    itemData.append("answer", answer);
    // itemData.append("image", image);

    const response = await axiosInstance.patch(
      about_API + "QNA_section/" + targetID,
      itemData,
      config
    );

    if (response.status === 200) {
      setQuestion("");
      setAnswer("");
      //   setImage(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Question`}
        value={question}
        placeholder={`Enter Title`}
        setState={setQuestion}
      />

      <LongTextInput
        label={`Answer`}
        value={answer}
        placeholder={`Enter Title`}
        setState={setAnswer}
      />
      {/* <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput> */}

      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default QNA_section;
