import { useState } from "react";
import UploadThumb from "../../../../../../Assets/img_upload.svg";
import { IMAGE_URL } from "../../../../../../Utilities/APIs/APIs";

import "./ImageInput.css";

function ImageInput({
  fieldId,
  state,
  setState,
  allowCreateImage,
  allowUpdateImage = false,
  children,
  heading = "",
}) {
  //for image preview
  const [imagePreview, setImagePreview] = useState({ preview: "" });
  const handleChange = (e) => {
    setState(e.target.files[0]);
    setImagePreview({
      preview: URL.createObjectURL(e.target.files[0]),
    });
  };

  return (
    <div>
      <label className="input_field_label" htmlFor={`fileInput${fieldId}`}>
        <span className="input_field_label">
          <h3 style={{ marginBottom: ".5rem", fontSize: "1.5rem" }}>
            {heading}
          </h3>
        </span>
        <div
          className="preview_image"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {allowUpdateImage && state && (
            <img
              src={
                imagePreview.preview ? imagePreview.preview : IMAGE_URL + state
              }
              alt="default thumb"
            />
          )}

          {allowCreateImage && (
            <img
              src={imagePreview.preview ? imagePreview.preview : UploadThumb}
              alt="default thumb"
            />
          )}
        </div>
      </label>
      <input
        id={`fileInput${fieldId}`}
        type="file"
        className="file_upload_input"
        onChange={handleChange}
      />
    </div>
  );
}

export default ImageInput;
