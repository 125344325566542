import { useEffect, useState } from "react";

import { MdOutlineFileUpload } from "react-icons/md";
import { HOME_API, about_API } from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import LongTextInput from "../../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
function UpdateHistoryOfSPSB({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [description, setDescription] = useState("");
  //   const [image, setImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        about_API + "history_of_SPSB/getSingleHistoryOfSPSB/" + targetID
      );
      console.log("hero data12345:", data);
      setTitle(data.title);
      setDescription(data.description);
      setButtonText(data.buttonText);
      //   setImage(data.image);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("buttonText", buttonText);
    // itemData.append("image", image);

    const response = await axiosInstance.patch(
      about_API + "history_of_SPSB/" + targetID,
      itemData,
      config
    );

    if (response.status === 200) {
      setTitle("");
      setDescription("");
      //   setImage(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <LongTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />

      <ShortTextInput
        label={`ButtonText`}
        value={buttonText}
        placeholder={`Enter Button Text`}
        setState={setButtonText}
      />
      {/* <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput> */}

      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateHistoryOfSPSB;
