import { useContext, useEffect, useState } from 'react'
import {  IMAGE_URL, MANAGE_ITEM_API } from '../../../../Utilities/APIs/APIs'

import CreateButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton'
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable'
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader'
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth'
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell'
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow'
import Modal from '../../../Partials/Elements/Modal/Modal'
import ViewItem from './ItemCRUDS/ViewItem'
import CreateItem from './ItemCRUDS/CreateItem'
import UpdateItem from './ItemCRUDS/UpdateItem'
import CRUDDeleteBoilerPlate from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate'
// import ActionButtonsCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell'
import ImageCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell'
import { useNavigate } from 'react-router-dom'
import ViewButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton'
import EditButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton'
import CRUDButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton'
import { RiImage2Line, RiRhythmLine } from 'react-icons/ri'
import Search from '../../../Partials/Elements/Search/Search'
import DeleteButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton'
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance'
import AppContext from '../../../../AppContext/AppContext'
import DeleteItem from '../../../Partials/Layouts/DeleteItem/DeleteItem'


function Items() {

    const [items, setItems] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [displayItems, setDisplayItems] = useState(null)
    const [toggleFetch, setToggleFetch] = useState(false)


    const { employee } = useContext(AppContext)
    const axiosInstance = useAxiosInstance()

    useEffect(() => {

     

        async function fetchAndSetItems() {
            const { data } = await axiosInstance.get( MANAGE_ITEM_API)
            setItems(data)
        }
        fetchAndSetItems()

    }, [toggleFetch, axiosInstance])

    useEffect(() => {
        if (items) {
            if (searchQuery === '') {
                setDisplayItems(items)
            } 
            if (searchQuery !== '') {
                const itemsToShow = items.filter(i => i.name.toLowerCase().includes(searchQuery.toLowerCase()))
                setDisplayItems(itemsToShow)
            }
        }
    }, [items, searchQuery])
    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    const navigate = useNavigate()

    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Items (${items?.length} in total)`}</h1>
                    <div>
                        <Search value={searchQuery} setState={setSearchQuery} />
                        <CreateButton
                            screenTopicSingular='Item'
                            setShowModal={setShowModal}
                            setShowCreateForm={setShowCreateForm}
                        />
                    </div>
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Item Name' />
                        <CRUDth th='Image' />
                        <CRUDth th='Price' />
                        <CRUDth th='IsVisible' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {displayItems?.map(item => (
                            <CRUDTableRow key={item._id}>
                                <ShortTextCell text={item.name} />
                                <ImageCell imgSrc={IMAGE_URL + item.image} alt={item.name} />
                                <ShortTextCell text={item.price} />
                                <ShortTextCell text={item.isVisible === true ? 'TRUE' : 'FALSE'} />
                                {/* <ActionButtonsCell
                                    //common props
                                    setShowModal={setShowModal}
                                    setShowViewSection={setShowViewSection}
                                    targetID={item._id}
                                    setTargetID={setTargetID}
                                    // props to allow update
                                    allowUpdate
                                    setShowUpdateForm={setShowUpdateForm}
                                    // props to allow delete
                                    allowDelete
                                    setShowDeleteSection={setShowDeleteSection}
                                /> */}
                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={item._id}
                                        setTargetID={setTargetID}
                                    />
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={item._id}
                                        setTargetID={setTargetID}
                                    />
                                    {/* <CRUDButton handleClick={() => navigate('/itemSizesFromItem/' + item._id)}>
                                        <RiRhythmLine />
                                    </CRUDButton> */}
                                    <CRUDButton handleClick={() => navigate('/item_assets_from_items/' + item._id)}>
                                        <RiImage2Line />
                                    </CRUDButton>
                                    <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={item._id}
                                        setTargetID={setTargetID}
                                    />
                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Item`) ||
                        (showCreateForm && `Create Item`) ||
                        (showUpdateForm && `Update Item`) ||
                        (showDeleteSection && `Delete Item`)
                    }
                >
                    {
                        showViewSection &&
                        <ViewItem
                            targetID={targetID}
                            employee={employee}
                        />
                    }
                    {
                        showCreateForm &&
                        <CreateItem
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showUpdateForm &&
                        <UpdateItem
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showDeleteSection &&
                        <DeleteItem
                            api={MANAGE_ITEM_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            // delete props 
                            setShowDeleteSection={setShowDeleteSection}
                            //field props
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }
        </>
    )
}

export default Items