import { RiMapPin2Fill } from "react-icons/ri";

import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { useContext, useState } from "react";
import AppContext from "../../../AppContext/AppContext";
import Modal from "../../Partials/Elements/Modal/Modal";
import AboutUpdateHeroForm from "./AboutForm/AboutUpdateHeroForm";
import OurMissionForm from "./AboutForm/OurMissionForm";
import UpdateFuturePlanForm from "./AboutForm/FuturePlanForm";
import UpdateAchivmentForm from "./AboutForm/AchievementsFrom";
// import HeroUpdateForm from "./HomeForms/HeroUpdateForm";
// import ConferenceAndMembershipForm from "./HomeForms/ConferenceAndMembershipForm";

function AboutContent() {
  const [showUpdateHeroForm, setShowUpdateHeroForm] = useState(false);
  const [showOurMissionForm, setShowOurMissionForm] = useState(false);
  const [showFuturePlanForm, setShowFuturePlanForm] = useState(false);
  const [showAchievementsForm, setShowAchievementsForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { employee } = useContext(AppContext);

  const closeModal = () => {
    setShowOurMissionForm(false);
    setShowUpdateHeroForm(false);
    setShowFuturePlanForm(false);
    setShowAchievementsForm(false);

    setShowModal(false);
  };
  return (
    <>
      <NavCardList numOfCards={"eight"}>
        <NavCard
          cardName={"Top Section"}
          navCardLink="top-section"
          onClick={() => {
            setShowUpdateHeroForm(true);
            setShowModal(true);
          }}
        >
          <RiMapPin2Fill />
        </NavCard>
        <NavCard
          cardName={"Our Mission"}
          navCardLink="our-mission"
          onClick={() => {
            setShowOurMissionForm(true);
            setShowModal(true);
          }}
        >
          <RiMapPin2Fill />
        </NavCard>

        <NavCard
          cardName={"Future Plan"}
          navCardLink="#"
          onClick={() => {
            setShowFuturePlanForm(true);
            setShowModal(true);
          }}
        >
          <RiMapPin2Fill />
        </NavCard>
        <NavCard
          cardName={"Achievements"}
          navCardLink="#"
          onClick={() => {
            setShowAchievementsForm(true);
            setShowModal(true);
          }}
        >
          <RiMapPin2Fill />
        </NavCard>

        {/* <NavCard cardName={"History Of SPSB"} navCardLink="/historyOfSPSB">
          <RiMapPin2Fill />
        </NavCard> */}

        <NavCard
          cardName={"Secretary Possessions"}
          navCardLink="/secretaryPossessions"
        >
          <RiMapPin2Fill />
        </NavCard>

        <NavCard
          cardName={"President Possession"}
          navCardLink="/presidentPossession"
        >
          <RiMapPin2Fill />
        </NavCard>

        <NavCard cardName={"QNA Section"} navCardLink="/QNASection">
          <RiMapPin2Fill />
        </NavCard>
      </NavCardList>
      {showModal && (
        <Modal
          handleClose={closeModal}
          modalHeading={`${
            (showAchievementsForm && "Update Achivements") ||
            (showOurMissionForm && "Update Our Mission") ||
            (showFuturePlanForm && "Update Future Plan") ||
            (showUpdateHeroForm && "Update Top Section")
          }`}
        >
          {showUpdateHeroForm && (
            <AboutUpdateHeroForm
              employee={employee}
              targetID={"667ab8c70b638766f891cef5"}
              setShowUpdateForm={setShowUpdateHeroForm}
              setShowModal={setShowModal}
              // triggerFetch={triggerFetch}
            />
          )}
          {showOurMissionForm && (
            <OurMissionForm
              employee={employee}
              targetID={"667ab92e0b638766f891cf0c"}
              setShowUpdateForm={setShowOurMissionForm}
              setShowModal={setShowModal}
              // triggerFetch={triggerFetch}
            />
          )}

          {showFuturePlanForm && (
            <UpdateFuturePlanForm
              employee={employee}
              targetID={"667ab9b20b638766f891cf34"}
              setShowUpdateForm={setShowFuturePlanForm}
              setShowModal={setShowModal}
              // triggerFetch={triggerFetch}
            />
          )}

          {showAchievementsForm && (
            <UpdateAchivmentForm
              employee={employee}
              targetID={"667aba210b638766f891cf42"}
              setShowUpdateForm={setShowAchievementsForm}
              setShowModal={setShowModal}
              // triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default AboutContent;
