import { useState } from "react";
import { EVENTS_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateEvent({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  storeId,
}) {
  const axiosInstance = useAxiosInstance();

  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longdescription, setLongDescription] = useState("");
  const [category, setCategory] = useState("event");
  const [location, setLocation] = useState("");
  // const [latitude, setLatitude] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");
  const [dateString, setDateString] = useState("");
  const [time, setTime] = useState("");
  const [participationTitle, setParticipationTitle] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [image, setImage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("shortDescription", shortDescription);
    itemData.append("longdescription", longdescription);
    itemData.append("category", category);
    itemData.append("location", location);
    itemData.append("googleMapLink", googleMapLink);
    // itemData.append("longitude", longitude);
    itemData.append("dateString", dateString);
    itemData.append("time", time);
    itemData.append("participationTitle", participationTitle);
    itemData.append("bankName", bankName);
    itemData.append("branchName", branchName);
    itemData.append("accountHolderName", accountHolderName);
    itemData.append("accountNumber", accountNumber);
    itemData.append("precedence", precedence);
    itemData.append("image", image);
    // console.log(itemData);

    const response = await axiosInstance.post(EVENTS_API, itemData);
    // console.log(response);
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  // console.log("image is", image);
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Short Description`}
        value={shortDescription}
        placeholder={`Enter Short Description`}
        setState={setShortDescription}
      />
      <ShortTextInput
        label={`Long Description`}
        value={longdescription}
        placeholder={`Enter Long Description`}
        setState={setLongDescription}
      />
      {/* 
       <ShortTextInput
        label={`Category`}
        value={category}
        placeholder={`Enter Category`}
        setState={setCategory}
      />
  */}
      <ShortTextInput
        label={`Location`}
        value={location}
        placeholder={`Enter Location`}
        setState={setLocation}
      />
      <ShortTextInput
        label={`Location Link`}
        value={googleMapLink}
        placeholder={`Enter Location Link`}
        setState={setGoogleMapLink}
      />
      {/* <ShortTextInput
        label={`Longitude`}
        value={longitude}
        placeholder={`Enter Longitude`}
        setState={setLongitude}
      /> */}
      <DateInput
        label={`dateString`}
        value={dateString}
        placeholder={`Enter Date`}
        setState={setDateString}
      />
      <ShortTextInput
        label={`time`}
        value={time}
        placeholder={`Enter Time`}
        setState={setTime}
      />
      {/* <ShortTextInput
        label={`Participation Title`}
        value={participationTitle}
        placeholder={`Enter Participation Title`}
        setState={setParticipationTitle}
      /> */}
      {/* <ShortTextInput
        label={`Bank Name`}
        value={bankName}
        placeholder={`Enter Bank Name`}
        setState={setBankName}
      /> */}
      {/* <ShortTextInput
        label={`Branch Name`}
        value={branchName}
        placeholder={`Enter Branch Name`}
        setState={setBranchName}
      /> */}
      {/* <ShortTextInput
        label={`Account Holder Name`}
        value={accountHolderName}
        placeholder={`Enter Holder Name`}
        setState={setAccountHolderName}
      /> */}

      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter precedence`}
        setState={setPrecedence}
      />

      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Card Image
      </ImageInput>
      <FormSubmitButton text="Create Event" />
    </Form>
  );
}

export default CreateEvent;
