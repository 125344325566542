import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import { ITEM_ASSETS_API } from "../../../../Utilities/APIs/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"


function UpdateItemAssets({

    targetID, setShowUpdateForm, setShowModal, triggerFetch, itemID

}) {


    const [image, setImage] = useState('')
    const [precedence, setPrecedence] = useState('')
    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetItemAssets() {
            const { data } = await axiosInstance.get(ITEM_ASSETS_API + 'getSingleAsset/' + targetID)
            console.log(data, 'jdflfkhaskdf shfkshdf ');
            setImage(data?.image)
            setPrecedence(data?.precedence)
        }
        fetchAndSetItemAssets()

    }, [targetID, axiosInstance])

    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = new FormData()

        itemData.append('item', itemID)
        itemData.append('image', image)
        itemData.append('precedence', precedence)

        const response = await axiosInstance.patch(ITEM_ASSETS_API + targetID, itemData)

        if (response) {
            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ImageInput state={image} setState={setImage} allowUpdateImage={true}>Upload Image</ImageInput>
            <ShortTextInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Precedence`}
                setState={setPrecedence}
            />

            <FormSubmitButton text='Update Item Assets' />
        </Form>
    )
}

export default UpdateItemAssets