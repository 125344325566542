import { useState } from "react";

import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { EVENTS_VISIT_API } from "../../../../Utilities/APIs/APIs";

function CreateVisit({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  visitId,
}) {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("image", image);

    const response = await axiosInstance.post(
      EVENTS_VISIT_API + visitId,
      itemData
    );
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter title`}
        setState={setName}
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload visit Image
      </ImageInput>
      <FormSubmitButton text="Create Visit Image" />
    </Form>
  );
}

export default CreateVisit;
