import { useState } from "react";
import { APBC_Events_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateHotel({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  hotelId,
}) {
  const [name, setName] = useState("");
  const [locationLink, setLocationLink] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("hotel", hotelId);
    itemData.append("name", name);
    itemData.append("locationLink", locationLink);

    const response = await axiosInstance.post(
      APBC_Events_API + "nearbyhotels/getAllNearByHotelByEventId/" + hotelId,
      itemData
    );
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter title`}
        setState={setName}
      />
      <ShortTextInput
        label={`Location Link`}
        value={locationLink}
        placeholder={`Enter title`}
        setState={setLocationLink}
      />
      <FormSubmitButton text="Create Hotel" />
    </Form>
  );
}

export default CreateHotel;
