import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  CONTACT,
  ITEM_CATEGORIES_API,
  ITEM_SUBCATEGORIES_API,
  MANAGE_CATEGORY_API,
  MANAGE_ITEM_API,
  SUBCATEGORIES_API,
} from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
function ContactContentForm({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [mapLink, setMapLink] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");

  const [content, setContent] = useState([]);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchArrayData() {
      const { data } = await axiosInstance.get(CONTACT);

      console.log("contact 1636131", data);

      setContent(data);
    }

    fetchArrayData();
  }, [axiosInstance]);

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        CONTACT + "getSingleContactInfo/" + content[0]._id
      );
      setName(data.title);
      setDescription(data.description);
      setNumber(data.phone);
      setEmail(data.email);
      setAddress(data.address);
      setMapLink(data.mapLink);
      setFormTitle(data.formTitle);
      setFormDescription(data.formDescription);
    }

    fetchAndSetItemToUpdate();
  }, [content, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", name);
    itemData.append("description", description);
    itemData.append("phone", number);
    itemData.append("email", email);
    itemData.append("mapLink", mapLink);
    itemData.append("address", address);
    itemData.append("formTitle", formTitle);
    itemData.append("formDescription", formDescription);

    let response;
    if (content.length > 0) {
      response = await axiosInstance.patch(
        CONTACT + content[0]._id,
        itemData,
        config
      );
    } else {
      response = await axiosInstance.post(CONTACT, itemData, config);
    }

    if (response) {
      setName("");
      setDescription("");
      setNumber("");
      setEmail("");
      setAddress("");
      setMapLink("");
      setFormTitle("");
      setFormDescription("");

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={name}
        placeholder={`Enter Title`}
        setState={setName}
      />
      <LongTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ShortTextInput
        label={`Phone Number`}
        value={number}
        placeholder={`Enter Phone Number`}
        setState={setNumber}
      />

      <ShortTextInput
        label={`Email`}
        value={email}
        placeholder={`Enter Email `}
        setState={setEmail}
      />
      <ShortTextInput
        label={`Address`}
        value={address}
        placeholder={`Enter Address `}
        setState={setAddress}
      />
      <ShortTextInput
        label={`Map Link`}
        value={mapLink}
        placeholder={`Enter Latitude `}
        setState={setMapLink}
      />
      <ShortTextInput
        label={`Form Title`}
        value={formTitle}
        placeholder={`Enter Form Title `}
        setState={setFormTitle}
      />

      <ShortTextInput
        label={`Form Form Description`}
        value={formDescription}
        placeholder={`Enter Form Description`}
        setState={setFormDescription}
      />

      <FormSubmitButton text={content.length > 0 ? "Update" : "Create"} />
    </Form>
  );
}

export default ContactContentForm;
