

import InputBoilerplate from "./InputBoilerplate";
// import "./NumberInput.css";

function DateInput({ label, placeholder, setState, value }) {
  return (
    <InputBoilerplate
      type={"date"}
      label={label}
      value={value}
      placeholder={placeholder}
      setState={setState}
    />
  );
}

export default DateInput;
