import { useEffect, useState } from "react"

import { FEATURED_ITEMS_API, MANAGE_ITEM_API } from "../../../../Utilities/APIs/APIs"
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../Partials/Layouts/Forms/Form"
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"


function UpdateFeaturedItem({

    employee, setShowUpdateForm, setShowModal, triggerFetch, targetID

}) {

    const [item, setItem] = useState('')
    const [precedence, setPrecedence] = useState(0)

    const axiosInstance = useAxiosInstance()
    const [items, setItems] = useState(null)

    useEffect(() => {

        async function fetchAndSetFeaturedItem() {

            const { data } = await axiosInstance.get(FEATURED_ITEMS_API + targetID)

          setItem(data.item._id);
          setPrecedence(data.precedence);
        }
        fetchAndSetFeaturedItem()
    }, [axiosInstance, targetID])

    useEffect(() => {

        async function fetchAndSetItems() {
            const { data } = await axiosInstance.get(MANAGE_ITEM_API)
            setItems(data)
        }
        fetchAndSetItems()

    }, [axiosInstance])

    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = { item, precedence }

        const response = await axiosInstance.patch(FEATURED_ITEMS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <SelectInput value={item} setState={setItem}>
                <SelectOption optionValue={''} optionText={`Select Item`} />
                {items && items.map(item => (
                    <SelectOption key={item._id} optionValue={item._id} optionText={item.name} />
                ))}
            </SelectInput>
            <NumberInput
                label="Precedence"
                placeholder='Enter Precedence'
                value={precedence}
                setState={setPrecedence}
            />



            <FormSubmitButton text='Update Featured Item' />
        </Form>
    )
}

export default UpdateFeaturedItem