import React, { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../AppContext/AppContext";

export default function useFetchSingleJournal({ targetID }) {
  const [journal, setJournal] = useState(null);
  const axiosInstance = useAxiosInstance();
  const { toggleFetch } = useContext(AppContext);

  useEffect(() => {
    async function getJournal() {
      const { data } = await axiosInstance.get(
        "websiteContents/journal/" + targetID
      );
      setJournal(data);
    }
    getJournal();
  }, [targetID, axiosInstance, toggleFetch]);

  return journal;
}
