import { useState } from "react"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import { ITEM_ASSETS_API } from "../../../../Utilities/APIs/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"

function CreateItemAssets({

    employee, setShowCreateForm, setShowModal, triggerFetch, itemID

}) {


   const [image, setImage] = useState('')
   const [precedence, setPrecedence] = useState('')
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = new FormData()

        itemData.append('item', itemID)
        itemData.append('image', image)
        itemData.append('precedence', precedence)

        const response = await axiosInstance.post(ITEM_ASSETS_API, itemData)

        if (response) {
            
            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
             <ImageInput setState={setImage} allowCreateImage={true}>Upload Image</ImageInput> 
              
             <ShortTextInput
              label={`Precedence`}
              value={precedence}
              placeholder={`Precedence`}
              setState={setPrecedence}
            />

            <FormSubmitButton text='Create Item Assets' />
        </Form>
    )
}

export default CreateItemAssets