import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
import "./FormHeader.css";
function UpdateConferenceAndMembershipForm({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [conferenceTitle, setConferenceTitle] = useState("");
  const [membershipTitle, setMembershipTitle] = useState("");
  const [conferenceDescription, setConferenceDescription] = useState("");
  const [membershipDescription, setMembershipDescription] = useState("");
  const [image, setImage] = useState(null);
  const [conferenceIcon, setConferenceIcon] = useState(null);

  const [content, setContent] = useState([]);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchSingleData() {
      const { data } = await axiosInstance.get(
        HOME_API +
          "conferenceAndMembership/getSingleConferenceAndMembership/" +
          content[0]._id
      );
      setTitle(data?.title);
      setMembershipTitle(data?.membershipTitle);
      setConferenceTitle(data?.conferenceTitle);
      setConferenceDescription(data?.conferenceDescription);
      setMembershipDescription(data?.membershipDescription);
      setImage(data?.image);
      setConferenceIcon(data?.conferenceIcon);
    }

    fetchSingleData();
  }, [axiosInstance, content]);

  useEffect(() => {
    async function fetchArrayData() {
      const { data } = await axiosInstance.get(
        HOME_API + "conferenceAndMembership/"
      );
      console.log("hero array:", data);
      setContent(data);
    }

    fetchArrayData();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("conferenceTitle", conferenceTitle);
    itemData.append("membershipTitle", membershipTitle);
    itemData.append("conferenceDescription", conferenceDescription);
    itemData.append("membershipDescription", membershipDescription);
    itemData.append("conferenceIcon", conferenceIcon);
    itemData.append("image", image);

    let response;

    if (content.length > 0) {
      response = await axiosInstance.patch(
        HOME_API + "conferenceAndMembership/" + content[0]._id,
        itemData,
        config
      );
    } else {
      response = await axiosInstance.post(
        HOME_API + "conferenceAndMembership/",
        itemData,
        config
      );
    }

    console.log(response);

    if (response.status === 200 || response.status === 201) {
      setTitle("");
      setMembershipTitle("");
      setConferenceTitle("");
      setConferenceDescription("");
      setMembershipDescription("");
      setImage(null);
      setConferenceIcon(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit} hasImage>
        <ShortTextInput
          label={`Conference Title`}
          value={conferenceTitle}
          placeholder={`Enter Title`}
          setState={setConferenceTitle}
        />
        <ShortTextInput
          label={`Membership Title`}
          value={membershipTitle}
          placeholder={`Enter Membership Title`}
          setState={setMembershipTitle}
        />

        <LongTextInput
          label={`Conference Description`}
          value={conferenceDescription}
          placeholder={`Enter Conference Description`}
          setState={setConferenceDescription}
        />

        <LongTextInput
          label={`Membership Description`}
          value={membershipDescription}
          placeholder={`Enter Membership Description`}
          setState={setMembershipDescription}
        />

        <ImageInput
          heading={"Upload Image"}
          state={image}
          setState={setImage}
          allowUpdateImage={true}
          fieldId={"image"}
        ></ImageInput>

        <ImageInput
          heading={"Upload Conference Icon Image"}
          state={conferenceIcon}
          setState={setConferenceIcon}
          allowUpdateImage={true}
          fieldId={"iconImage"}
        ></ImageInput>

        <FormSubmitButton text={content.length > 0 ? "Update" : "Create"} />
      </Form>
    </>
  );
}

export default UpdateConferenceAndMembershipForm;
