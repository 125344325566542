import { useState } from "react";
import {
  EVENT_VISUALS_API,
  GALLERY_IMAGE_API,
} from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateVisual({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  eventId,
}) {
  console.log(eventId);
  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();
    itemData.append("eventId", eventId);
    itemData.append("image", image);

    const response = await axiosInstance.post(
      EVENT_VISUALS_API + eventId,
      itemData
    );

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Gallery Image
      </ImageInput>
      <FormSubmitButton text="Create Gallery Image" />
    </Form>
  );
}

export default CreateVisual;
