import {RiMapPin2Fill, RiSecurePaymentFill, RiUser2Line} from "react-icons/ri";
import NavCardList from "../../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../../Partials/Layouts/NavCardList/NavCard/NavCard";

function CustomerScreens() {
  return (
      <NavCardList numOfCards={'two'}>
        <NavCard cardName={'Payment'} navCardLink='/paymentDetails' ><RiSecurePaymentFill /></NavCard>
        <NavCard cardName={'Members'} navCardLink='/customers' ><RiUser2Line /></NavCard>
      </NavCardList>
  )
}

export default CustomerScreens