import { RiMap2Line } from "react-icons/ri";
import { DIVISIONS_API } from "../../../Utilities/APIs/APIs";
import NCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD";

function Divisions() {
  return (
    <NCRUD
        // common props
        api={DIVISIONS_API}
        screenTopicSingular={'Division'}
        screenTopicPlural={'Divisions'}
        
        // crud header props
        showTotalInHeading

        // action button props
        extraActionButton
        extraActionButtonHandleClick='/districts'
        extraActionButtonChildren={<RiMap2Line />}
                 
    />
  )
}

export default Divisions