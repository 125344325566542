import React, { useContext } from 'react'
// import ProfileImg from '../../../Assets/UserProfileImage.png';
import ScreenHolder from '../../Partials/Layouts/ScreenHolder/ScreenHolder'
import ProfileInfo from './ProfileInfo/ProfileInfo'

import './MyProfile.css'
import { BASE_URL } from '../../../Utilities/APIs/APIs';
import AppContext from '../../../AppContext/AppContext';

function MyProfile() {

    const {employee} = useContext(AppContext)

    return (
        <ScreenHolder>
            <div className="profile_view">
                <div className="upload-picture">
                    <div className="upload-picture-inner">
                        <label htmlFor="fileUpload">
                            <img 
                                src={BASE_URL + employee?.dp.slice(6, employee?.dp.length)} 
                                alt="upload"
                                id="uplodedImg" 
                            />
                        </label>
                        <h4>{employee?.name}</h4>
                    </div>
                </div>
                <ProfileInfo />
            </div>
        </ScreenHolder>
    )
}

export default MyProfile