import { useState, useEffect } from 'react'
import { APP_SETTINGS_API, IMAGE_URL } from '../../../../Utilities/APIs/APIs'
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance'

function Logo() {

    const [logo, setLogo] = useState(null)
	
    const axiosInstance = useAxiosInstance()

	useEffect(() => {

        async function fetchData() {
            const res = await axiosInstance.get(APP_SETTINGS_API + 'manageAppSettings/');
            const data = await res.json();
            setLogo(data[0].image)
        }

		fetchData()
        
	}, [])
    
    return (
        <>
            {logo && <img src={IMAGE_URL + logo} alt="logo" />}
        </>
    )
}

export default Logo