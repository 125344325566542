import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import {SOCIAL_LINKS_API, VATS_API } from "../../../../Utilities/APIs/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import SwitchInput from "../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"


function UpdateSocialLinks({targetID, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [link, setLink] = useState('')
    const [visibility, setIsVisibility] = useState(false)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetvatsInfo() {
            const { data } = await axiosInstance.get(SOCIAL_LINKS_API + 'getSingleSocialLink/' + targetID)
            setName(data?.name)
            setLink(data?.link)
            setIsVisibility(data?.visibility)
        }
        fetchAndSetvatsInfo()
    }, [targetID, axiosInstance])

    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('link', link)
        itemData.append('visibility', visibility)


        const response = await axiosInstance.patch(SOCIAL_LINKS_API + targetID, itemData)

         if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }
     }

return (
    <Form onSubmit={handleSubmit} hasImage>

        <ShortTextInput
            label="Name"
            placeholder='Enter Name'
            value={name}
            setState={setName}
        />
        <ShortTextInput
            label="Link"
            placeholder='Enter Link'
            value={link}
            setState={setLink}
        />

        <SwitchInput
            label={'visibility'}
            toggleSwitch={() => setIsVisibility(prevState => !prevState)}
            checked={visibility}
        />

        <FormSubmitButton text='Update Social Links' />
    </Form>
)
}

export default UpdateSocialLinks
