
import { useState } from "react"
import { TESTIMONIALS_API } from "../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"


function CreateTestimonial({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

   const [name, setName] = useState('')
   const [designation, setDesignation] = useState('')
    const [testimonial, setTestimonial] = useState('')
    const [precedence, setPrecedence] = useState(0)
    const [image, setImage] = useState()

    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('designation', designation)
        itemData.append('testimonial', testimonial)
        itemData.append('precedence', precedence)
        itemData.append('image', image)
    

        const response = await axiosInstance.post(TESTIMONIALS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label= "Name"
                placeholder='Enter name'
                value={name}
                setState={setName}
            />

            <ShortTextInput
                label= "Designation"
                placeholder='Enter Designation'
                value={designation}
                setState={setDesignation}
            />

            <ShortTextInput
                label= "Testimonial"
                placeholder='Enter Testimonial'
                value={testimonial}
                setState={setTestimonial}
            />

            
            <ShortTextInput
                label= "Precedence"
                placeholder='Enter Precedence'
                value={precedence}
                setState={setPrecedence}
            />

            <ImageInput setState={setImage} allowCreateImage={true}>Upload Image</ImageInput>

            <FormSubmitButton text='Create Testimonial' />
        </Form>
    )
}

export default CreateTestimonial