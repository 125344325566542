import { useContext } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../AppContext/AppContext";
import useFetchSingleJournal from "../Hooks/useFetchSingleJournal";

function ApproveJournal({ targetID, setShowApproveModal, setShowModal }) {
  const journal = useFetchSingleJournal({ targetID });

  const axiosInstance = useAxiosInstance();
  const { triggerFetch } = useContext(AppContext);

  async function handleDelete(id, data) {
    const res = await axiosInstance.patch(
      "websiteContents/journal/" + id,
      data
    );

    if (res.data) {
      setShowModal(false);
      setShowApproveModal(false);
      triggerFetch();
    }
  }

  return (
    <div className="delete_item">
      <div className="approve_content">
        <h1>Are you sure?</h1>
        <p>
          Please confirm you want to{" "}
          {journal?.isApproved ? "Reject" : "Approve"} this Journal.
        </p>
        <div className="approve_container">
          <button
            onClick={() =>
              handleDelete(targetID, { isApproved: !journal?.isApproved })
            }
            className="approve"
          >
            {journal?.isApproved ? "Reject" : "Approve"}
          </button>
          <button onClick={() => setShowModal(false)} className="approve">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ApproveJournal;
