import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {  MANAGE_ITEM_API, ORDERS_API, SIZES_API } from "../../../Utilities/APIs/APIs"
// import Modal from "../../Partials/Elements/Modal/Modal"
// import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance"

function OrderItemsFromOrders() {

    const [orderItems, setOrderItems] = useState(null)
    console.log(orderItems)
    const [items, setItems] = useState(null)
    const [sizes, setSizes] = useState(null)
    // const [showModal, setShowModal] = useState(false)
    // const [targetID, setTargetID] = useState(null)
    // const [showViewSection, setShowViewSection] = useState(false)
    const {orderID} = useParams()

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetOrderItems() {
            const {data} = await axiosInstance.get(ORDERS_API + 'orderItemsFromOrder/' + orderID)
            setOrderItems(data)
        }
        fetchAndSetOrderItems()

        async function fetchAndSetItems() {
            const {data} = await axiosInstance.get(MANAGE_ITEM_API)
            setItems(data)
        }

        fetchAndSetItems()

        async function fetchAndSetSizes() {
            const {data} = await axiosInstance.get(SIZES_API)
            setSizes(data)
        }

        fetchAndSetSizes()

    }, [axiosInstance, orderID])

    // function handleClose() {
    //     setShowModal(false);
    //     setShowViewSection(false);
    // }

    return (
      <>
          <div className='crud_holder'>
              <div className="body_header">
                  <h1 className="heading">{`Order Items (${orderItems?.length} in total)`}</h1>
              </div>
              <CRUDTable>
                  <CRUDTableHeader>
                      <CRUDth th= 'Item' />
                      {/* <CRUDth th= 'Size' /> */}
                      <CRUDth th= 'Quantity' />
                      <CRUDth th= 'Unit Price' />
                      <CRUDth th= 'Total' />
                      {/* <CRUDth th= 'Actions' /> */}
                  </CRUDTableHeader>
                  <tbody>
                      {orderItems?.map(orderItem => (
                          <CRUDTableRow key={orderItem._id}>
                              <ShortTextCell text={items?.find(i => i._id === orderItem.item)?.name} />
                              {/* <ShortTextCell text={sizes?.find(s => s._id === orderItem.size)?.name} /> */}
                              <ShortTextCell text={orderItem.quantity} />
                              <ShortTextCell text={orderItem.unitPrice} />
                              <ShortTextCell text={orderItem.total} />
                              {/* <td className="action_button_cell">
                                  <ViewButton
                                  setShowModal={setShowModal}
                                  setShowViewSection={setShowViewSection}
                                  targetID={order._id}
                                  setTargetID={setTargetID}
                                  />
                              </td> */}
                          </CRUDTableRow>
                      ))}
                  </tbody>
              </CRUDTable>
          </div>
          {/* {showModal && 
              <Modal
                  handleClose={handleClose}
                  modalHeading={
                      (showViewSection && `View Address`)
                  }
              >
                  {
                      showViewSection &&
                          <ViewOrder
                              targetID={targetID}
                              employee={employee}
                          />
                  }    
              </Modal>
          } */}
      </>
    )
}

export default OrderItemsFromOrders