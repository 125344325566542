import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import { FEATURED_CATEGORIES_API, MANAGE_CATEGORY_API } from "../../../../../Utilities/APIs/APIs"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"


function CreateFeaturedCategories({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {
 
    const [category, setCategory] = useState("");
    const [precedence, setPrecedence] = useState("");

    const [categories, setCategories] = useState(null)

    const axiosInstance = useAxiosInstance()
     useEffect(() => {
         async function fetchAndSetCategories() {
             const { data } = await axiosInstance.get(MANAGE_CATEGORY_API)
             setCategories(data)
         }
         fetchAndSetCategories()
 
     }, [axiosInstance])


    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = {category, precedence}

        console.log("🚀 ~ handleSubmit ~ itemData:", itemData)
        const response = await axiosInstance.post(FEATURED_CATEGORIES_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
        <SelectInput value={category} setState={setCategory}>
                <SelectOption optionValue={0} optionText={`Select Categories`} />
                {categories?.map(sItem => (
                    <SelectOption
                        key={sItem._id}
                        optionValue={sItem._id}
                        optionText={sItem.name}
                    />
                ))}
            </SelectInput>
            <SelectInput
            label={"Precedence"}
            value={precedence}
            setState={setPrecedence}
          >
            <SelectOption optionValue="" optionText="Select Precedence" />
            <SelectOption optionValue="1" optionText="1" />
            <SelectOption optionValue="2" optionText="2" />
            <SelectOption optionValue="3" optionText="3" />
            <SelectOption optionValue="4" optionText="4" />
          </SelectInput>

            <FormSubmitButton text='Create  Featured Categories' />
        </Form>
    )
}

export default CreateFeaturedCategories