import { useContext } from "react";
import { TICKET_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import "./ApproveItem.css";
import AppContext from "../../../../AppContext/AppContext";

function ApproveItem({
  api,
  targetID,
  setShowDeleteSection,
  setShowModal,
  email,
}) {
  const axiosInstance = useAxiosInstance();
  const { triggerFetch } = useContext(AppContext);

  async function handleDelete(id, data) {
    const res = await axiosInstance.patch(TICKET_API + "/" + id, {
      isApprove: data,
      email: email,
    });

    if (res.data) {
      setShowModal(false);
      setShowDeleteSection(false);
      triggerFetch();
    }
  }

  return (
    <div className="delete_item">
      <div className="approve_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to Approve this Ticket.</p>
        <div className="approve_container">
          <button
            onClick={() => handleDelete(targetID, "Approve")}
            className="approve"
          >
            Approve
          </button>
          <button
            onClick={() => handleDelete(targetID, "Rejected")}
            className="approve"
          >
            Rejected
          </button>

          {/* <button
            className="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default ApproveItem;
