import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { about_API } from "../../../../../Utilities/APIs/APIs";
import Image from "../../../../Partials/Elements/Image/Image";

function ViewSecretaryPossessions({ targetID }) {
  const [details, setDetails] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        about_API +
          "secretaryPossessions/getSingleSecretaryPossessions/" +
          targetID
      );
      console.log("team12345", data);
      setDetails(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {details && (
        <>
          <h1>Name</h1>
          <p>{details?.name}</p>

          <h1>Date String</h1>
          <p>{details?.dateString}</p>
        </>
      )}
    </div>
  );
}

export default ViewSecretaryPossessions;
