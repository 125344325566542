import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { about_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
function OurMissionForm({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [content, setContent] = useState([]);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        about_API + "ourMission/getSingleOurMission/" + content[0]._id
      );

      console.log(data);
      setName(data.title);
      setDescription(data.description);
      setImage(data.image);
    }

    fetchAndSetItemToUpdate();
  }, [content, axiosInstance]);

  useEffect(() => {
    async function fetchArrayData() {
      const { data } = await axiosInstance.get(about_API + "ourMission/");

      setContent(data);
    }

    fetchArrayData();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", name);
    itemData.append("description", description);
    itemData.append("image", image);

    let response;
    if (content.length > 0) {
      response = await axiosInstance.patch(
        about_API + "ourMission/" + content[0]._id,
        itemData,
        config
      );
    } else {
      response = await axiosInstance.post(
        about_API + "ourMission/",
        itemData,
        config
      );
    }

    if (response) {
      setName("");
      setDescription("");
      setImage(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={name}
        placeholder={`Enter Title`}
        setState={setName}
      />
      <LongTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />

      <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowUpdateImage={true}
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput>

      <FormSubmitButton text={content.length > 0 ? "Update" : "Create"} />
    </Form>
  );
}

export default OurMissionForm;
