import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { FEATURED_ITEMS_API, HOME_API } from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateFeaturedItem from "../../ItemScreens/FeaturedItems/CreateFeaturedItem";
import UpdateFeaturedItem from "../../ItemScreens/FeaturedItems/UpdateSubcategory";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import ViewSubcategory from "../../ItemGroups/CategoryScreens/SubCategories/ViewSubcategory";
import CreateTeamForm from "../HomeForms/CreateTeamForm";
import UpdateTeamForm from "../HomeForms/UpdateTeamForm";
import ViewTeamDetail from "../HomeForms/ViewTeamDetail";

function TeamSection() {
  const [OurTeam, setOurTeam] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetOurTeam() {
      const { data } = await axiosInstance.get(HOME_API + "ourTeam");

      console.log(data);
      setOurTeam(data);
    }
    fetchAndSetOurTeam();
  }, [toggleFetch, axiosInstance, showModal]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Teams (${OurTeam?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular="Team"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Precedence" />
            <CRUDth th="Image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {OurTeam?.map((team) => (
              <CRUDTableRow key={team._id}>
                <ShortTextCell text={team?.name} />
                {/* <ShortTextCell text={team?.description} /> */}
                <ShortTextCell text={team?.precedence} />
                {/* <ShortTextCell text={team?.designation} /> */}
                <ImageCell
                  imgSrc={process.env.REACT_APP_SPACES_URL + team.image}
                  alt={"team member"}
                />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={team._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={team._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={team._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Team Member`) ||
            (showUpdateForm && `Update Team Member`) ||
            (showDeleteSection && `Delete Team Member`)
          }
        >
          {showCreateForm && (
            <CreateTeamForm
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateTeamForm
              targetID={targetID}
              employee={employee}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={HOME_API + "ourTeam/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Team Member`}
        >
          <ViewTeamDetail targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default TeamSection;
