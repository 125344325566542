import { useContext, useEffect, useState } from "react"

import { FEATURED_ITEMS_API } from "../../../../Utilities/APIs/APIs"
import AppContext from "../../../../AppContext/AppContext"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem"
import CreateFeaturedItem from "./CreateFeaturedItem"
import UpdateFeaturedItem from "./UpdateSubcategory"

function FeaturedItems() {

    const [featuredItems, setFeaturedItems] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)
    const { employee } = useContext(AppContext)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetFeaturedItems() {
            const { data } = await axiosInstance.get(FEATURED_ITEMS_API)
            setFeaturedItems(data)
        }
        fetchAndSetFeaturedItems()


    }, [toggleFetch, axiosInstance])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }


    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Featured Items (${featuredItems?.length} in total)`}</h1>
                    <CreateButton
                        screenTopicSingular='Featured Item'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    />
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Precedence' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {featuredItems?.map(featuredItem => (
                            <CRUDTableRow key={featuredItem._id}>
                                <ShortTextCell text={featuredItem?.item?.name} />
                                <ShortTextCell text={featuredItem?.precedence} />
  


                                <td className="action_button_cell">
                                    {/* <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={featuredItem._id}
                                        setTargetID={setTargetID}
                                    /> */}
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={featuredItem._id}
                                        setTargetID={setTargetID}
                                    />
                                    <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={featuredItem._id}
                                        setTargetID={setTargetID}
                                    />

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Featured Item`) ||
                        (showUpdateForm && `Update Featured Item`) ||
                        (showDeleteSection && `Delete Featured Item`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateFeaturedItem
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}

                        />
                    }
                    {
                        showUpdateForm &&
                        <UpdateFeaturedItem
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                     
                        />
                    }

                    
                    {
                        showDeleteSection &&
                        <DeleteItem
                            api={FEATURED_ITEMS_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Featured Item`)
                    }
                >
                    {/* <ViewSubcategory
                        targetID={targetID}
                    /> */}
                </Modal>
            }
        </>
    )
}

export default FeaturedItems