import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Image from "../../../Partials/Elements/Image/Image";
import { GALLERY_API } from "../../../../Utilities/APIs/APIs";

function ViewGallery({ targetID }) {
  const [galleryInfo, setGalleryIngo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetGalleryIngo() {
      const { data } = await axiosInstance.get(
        GALLERY_API + "getSingleGallery/" + targetID
      );
      setGalleryIngo(data);
    }
    fetchAndsetGalleryIngo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {galleryInfo && (
        <>
          <h1>Title</h1>
          <p>{galleryInfo?.title}</p>

          <h1>Description</h1>
          <p>{galleryInfo?.description}</p>

          <h1>Card Image</h1>
          <Image
            imgLink={galleryInfo?.cardImage}
            imgAlt={"Card Front Image"}
          />
        </>
      )}
    </div>
  );
}

export default ViewGallery;
