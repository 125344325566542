import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
function CreateTeamForm({
  employee,
  targetID,
  setShowCreateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [designation, setDesignation] = useState("");
  const [precedence, setPrecedence] = useState("");
  const [image, setImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("description", description);
    itemData.append("designation", designation);
    itemData.append("precedence", precedence);
    itemData.append("image", image);

    const response = await axiosInstance.post(
      HOME_API + "ourTeam/",
      itemData,
      config
    );

    console.log(response);

    if (response.status === 200) {
      setName("");
      setDescription("");
      setImage(null);

      setShowCreateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <LongTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />

      <LongTextInput
        label={`Designation`}
        value={designation}
        placeholder={`Enter Designation`}
        setState={setDesignation}
      />

      <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />

      <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowCreateImage={true}
      >
        {/* <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div> */}
      </ImageInput>

      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateTeamForm;
