import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateYoutubeLink({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [link, setLink] = useState("");
  const [content, setContent] = useState([]);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        HOME_API + "youtubeLink/" + content[0]._id
      );
      setLink(data.link);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance, content]);

  useEffect(() => {
    async function fetchArrayData() {
      const { data } = await axiosInstance.get(HOME_API + "youtubeLink/");
      console.log("hero data12345:", data);
      setContent(data);
    }

    fetchArrayData();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("link", link);
    let response;

    if (content.length > 0) {
      response = await axiosInstance.patch(
        HOME_API + "youtubeLink/" + content[0]._id,
        itemData,
        config
      );
    } else {
      response = await axiosInstance.post(
        HOME_API + "youtubeLink/",
        itemData,
        config
      );
    }

    if (response.status === 200 || response.status === 201) {
      setLink("");
      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label={`link`}
        value={link}
        placeholder={`Enter link`}
        setState={setLink}
      />

      <FormSubmitButton text={content?.length > 0 ? "Update" : "Create"} />
    </Form>
  );
}

export default UpdateYoutubeLink;
