import { useEffect, useState } from "react";
import { RiCheckboxCircleFill, RiCloseCircleFill, RiGiftFill, RiRepeatFill, RiTruckFill } from "react-icons/ri";
import { ORDERS_API } from "../../../Utilities/APIs/APIs";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import ScreenHolder from "../../Partials/Layouts/ScreenHolder/ScreenHolder";
import useAxiosInstance from './../../../Utilities/Hooks/useAxiosInstance';

function OrderScreens() {

    const [orders, setOrders] = useState(null)
    console.log(orders, 'orders');

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetItems() {
            const {data} = await axiosInstance.get(ORDERS_API +'getOrderCountsByStatus')
            setOrders(data)
        }
        fetchAndSetItems()
    }, [axiosInstance])

    return (
        <ScreenHolder>
            <NavCardList numOfCards='five'>
                <NavCard 
                    cardName={`Processing(${orders?.filter(i => i.status === 'processing')?.length})`} 
                    navCardLink={'/ordersFromStatus/processing'}
                >
                    <RiRepeatFill />
                </NavCard>
                <NavCard 
                    cardName={`To Be Delivered(${orders?.filter(i => i.status === 'toBeDelivered')?.length})`} 
                    navCardLink={'/ordersFromStatus/toBeDelivered'}
                >
                    <RiGiftFill />
                </NavCard>
                <NavCard 
                    cardName={`Shipped(${orders?.filter(i => i.status === 'shipped')?.length})`} 
                    navCardLink={'/ordersFromStatus/shipped'}
                >
                    <RiTruckFill />
                </NavCard>
                <NavCard 
                    cardName={`Completed(${orders?.filter(i => i.status === 'completed')?.length})`} 
                    navCardLink={'/ordersFromStatus/completed'}
                >
                    <RiCheckboxCircleFill />
                </NavCard>
                <NavCard 
                    cardName={`Canceled(${orders?.filter(i => i.status === 'canceled')?.length})`} 
                    navCardLink={'/ordersFromStatus/canceled'}
                >
                    <RiCloseCircleFill />
                </NavCard>
            </NavCardList>
        </ScreenHolder>
    )
}

export default OrderScreens