import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import { MANAGE_COLLECTIONS_API } from "../../../../../Utilities/APIs/APIs"


function ViewCollections({ targetID, employee }) {

    const [collection, setCollection] = useState(null)
    // console.log("🚀 ~ ViewCollections ~ category:", collection)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetCollections() {

            const { data } = await axiosInstance.get(MANAGE_COLLECTIONS_API + 'getSingleCollections/' + targetID)
            setCollection(data);
        }
        fetchAndSetCollections()
    }, [axiosInstance, targetID])

    return (
        <div className="crud_view_content">

            {collection &&
                <>

                    <h1>Name</h1>
                    <p>{collection?.name}</p>

                    <h1>Description</h1>
                    <p>{collection?.description}</p>


                </>
            }
        </div>
    )
}

export default ViewCollections