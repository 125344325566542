import { useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import { SUBCATEGORIES_API } from "../../../../../Utilities/APIs/APIs"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"


function CreateSubcategory({

    employee, setShowCreateForm, setShowModal, triggerFetch, categoryId

}) {

   const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [precedence, setPrecedence] = useState(0)
    
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = new FormData()

        itemData.append('category', categoryId)
        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('precedence', precedence)

        const response = await axiosInstance.post(SUBCATEGORIES_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label= "Name"
                placeholder='Enter name'
                value={name}
                setState={setName}

            />
            <ShortTextInput
                label= "Description"
                placeholder='Enter description'
                value={description}
                setState={setDescription}

            />
            <NumberInput
                label="Precedence"
                placeholder='Enter Precedence'
                value={precedence}
                setState={setPrecedence}
            />



            <FormSubmitButton text='Create Subategory' />
        </Form>
    )
}

export default CreateSubcategory