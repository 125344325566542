import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../../AppContext/AppContext";
import {
  ALL_EVENTS_API,
  EVENTS_VISIT_API,
} from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import CreateVisit from "./CreateVisit";
import UpdateVisit from "./UpdateVisit";
import ViewVisit from "./viewVisit";

function Visit() {
  const { visitId } = useParams();
  console.log(visitId);

  const [showViewSection, setShowViewSection] = useState("");
  const [visits, setVisits] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetVisits() {
      const { data } = await axiosInstance.get(EVENTS_VISIT_API + visitId);
      setVisits(data);
    }
    fetchAndSetVisits();
  }, [axiosInstance, toggleFetch, setVisits, visitId]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">Visit ({visits?.length} in total)</h1>

          <CreateButton
            screenTopicSingular={"Places Visit"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="No" />
            <CRUDth th="Name" />
            <CRUDth th="image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {visits &&
              visits.length > 0 &&
              visits?.map((hotel, index) => (
                <CRUDTableRow key={hotel?._id}>
                  <ShortTextCell text={index + 1} />
                  <ShortTextCell text={hotel?.name} />
                  <ImageCell
                    imgSrc={process.env.REACT_APP_SPACES_URL + hotel?.image}
                    alt={"team member"}
                  />

                  <td className="action_button_cell">
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={hotel._id}
                      setTargetID={setTargetID}
                    />
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={hotel._id}
                      setTargetID={setTargetID}
                    />
                    <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={hotel._id}
                      setTargetID={setTargetID}
                    />
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Places`) ||
            (showUpdateForm && `Update Places `) ||
            (showDeleteSection && `Delete Places`)
          }
        >
          {showCreateForm && (
            <CreateVisit
              employee={employee}
              visitId={visitId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateVisit
              employee={employee}
              visitId={visitId}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={ALL_EVENTS_API + "visit/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
      {showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Visit Item`}
        >
          <ViewVisit targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default Visit;
