import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API, about_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
import AddListItem from "../../../Partials/Layouts/AddListItem/AddListItem";
function UpdateFuturePlanForm({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  const [futurePlanList, setFuturePlanList] = useState([]);
  const [benefitText, setbenefitText] = useState([]);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState([]);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        about_API + "futurePlan/getSingleFuturePlan/" + content[0]._id
      );
      setTitle(data.title);
      // setDescription(data.description);
      setFuturePlanList(data.futurePlanList);
      setImage(data.image);
    }

    fetchAndSetItemToUpdate();
  }, [content, axiosInstance]);

  useEffect(() => {
    async function fetchArrayData() {
      const { data } = await axiosInstance.get(about_API + "futurePlan/");

      setContent(data);
    }

    fetchArrayData();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", title);
    // itemData.append("description", description);
    itemData.append("futurePlanList", futurePlanList.join(","));
    itemData.append("image", image);

    let response;
    if (content.length > 0) {
      response = await axiosInstance.patch(
        about_API + "futurePlan/" + content[0]._id,
        itemData,
        config
      );
    } else {
      response = await axiosInstance.post(
        about_API + "futurePlan/",
        itemData,
        config
      );
    }

    if (response.status === 200 || response.status === 201) {
      setTitle("");
      // setDescription("");
      setImage(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <AddListItem list={futurePlanList} setList={setFuturePlanList} />

      <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowUpdateImage={true}
      />

      <FormSubmitButton text={content.length > 0 ? "Update" : "Create"} />
    </Form>
  );
}

export default UpdateFuturePlanForm;
