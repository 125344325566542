import { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../AppContext/AppContext";

export default function useFetchJournals() {
  const { toggleFetch } = useContext(AppContext);
  const [journal, setJournal] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetFeaturedItems() {
      const { data } = await axiosInstance.get("websiteContents/journal");
      console.log("data", data);
      setJournal(data);
    }
    fetchAndSetFeaturedItems();
  }, [toggleFetch, axiosInstance]);

  return journal;
}
