
import { CUSTOMERS_API } from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
// import "./ApproveItem.css";

function CustomerApproveItem({
  api,
  targetID,
  setShowDeleteSection,
  setShowModal,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  async function handleDelete(id, data) {
    const res = await axiosInstance.patch(CUSTOMERS_API + "manageCustomers/verifiedCustomer/" + id, {
      isApproved: data,
    });

    if (res.data) {
      setShowModal(false);
      setShowDeleteSection(false);
      triggerFetch();
    }
  }

  return (
    <div className="delete_item">
      <div className="approve_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to Approve this Payment.</p>
        <div className="approve_container">
          <button
            onClick={() => handleDelete(targetID, "approved")}
            className="approve"
          >
            Approve
          </button>
          <button
            onClick={() => handleDelete(targetID, "rejected")}
            className="approve"
          >
            Rejected
          </button>

          {/* <button
            className="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default CustomerApproveItem;
