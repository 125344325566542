import { useEffect, useState } from "react"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import { SUBCATEGORIES_API } from "../../../../../Utilities/APIs/APIs"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"




function UpdateSubcategory({ targetID, setShowUpdateForm, setShowModal, triggerFetch, categoryId

}) {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [precedence, setPrecedence] = useState(0)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetCategory() {

            const { data } = await axiosInstance.get(SUBCATEGORIES_API + targetID)
            setName(data.name);
            setDescription(data.description);
            setPrecedence(data.precedence);
        }
        fetchAndSetCategory()
    }, [axiosInstance, targetID])

    async function handleSubmit(e) {

        const itemData = new FormData()

        itemData.append('category', categoryId)
        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('precedence', precedence)

        const response = await axiosInstance.patch(SUBCATEGORIES_API + targetID ,itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label="Name"
                placeholder='Enter name'
                value={name}
                setState={setName}

            />
            <ShortTextInput
                label="Description"
                placeholder='Enter description'
                value={description}
                setState={setDescription}

            />
            <NumberInput
                label="Precedence"
                placeholder='Enter Precedence'
                value={precedence}
                setState={setPrecedence}
            />



            <FormSubmitButton text='Update Category' />
        </Form>
    )
}

export default UpdateSubcategory