import { useState, useEffect } from "react"
import { FEATURED_CATEGORIES_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"

function ViewFeaturedCategories({ targetID, employee }) {

    const [featuredCategoy, setFeaturedCategoy] = useState(null)
    console.log("🚀 ~ ViewFeaturedCategories ~ featuredCategoy:", featuredCategoy)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {
        async function fetchAndSetCategories() {
            const { data } = await axiosInstance.get(FEATURED_CATEGORIES_API + 'getSingleFeaturedCategories/'+ targetID)
            setFeaturedCategoy(data)
        }
        fetchAndSetCategories()
    }, [axiosInstance, targetID])


    return (
        <div className="crud_view_content">
            <h1>Featured Categories</h1>
            {/* {featuredCategoy && featuredCategoy.map((category) => ( */}
                <>
                    {/* <div key={category?._id}>
                    </div> */}

                    <h2>Category</h2>
                <p>{featuredCategoy?.category}</p>
                    <h2>Precedence</h2>
                <p>{featuredCategoy?.precedence}</p>
                </>
            
        </div>
    )
}

export default ViewFeaturedCategories