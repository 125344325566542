import axios from 'axios'
import { useEffect, useState } from 'react'

import { SUBCATEGORIES_API, MANAGE_ITEM_API } from '../../../../../Utilities/APIs/APIs'

import Form from '../../../../Partials/Layouts/Forms/Form'
import ImageInput from '../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput'
import LongTextInput from '../../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput'
import NumberInput from '../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput'
import RadioInput from '../../../../Partials/Layouts/Forms/FormInputs/RadioInput/RadioInput'
import RadioInputGroup from '../../../../Partials/Layouts/Forms/FormInputs/RadioInput/RadioInputGroup'
import SelectInput from '../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput'
import SelectOption from '../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption'
import ShortTextInput from '../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
import SwitchInput from '../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput'
import FormSubmitButton from '../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import { MANAGE_CATEGORY_API } from './../../../../../Utilities/APIs/APIs';
import useAxiosInstance from './../../../../../Utilities/Hooks/useAxiosInstance';


function CreateItem({

    employee, setShowCreateForm, setShowModal

}) {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState(0)
    const [isAvailable, setIsAvailable] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [image, setImage] = useState()

    const [categoryOrSubcategory, setCategoryOrSubcategory] = useState('category')
    const [category, setCategory] = useState('')
    const [subcategory, setSubcategory] = useState('')

    const [allCategories, setAllCategories] = useState(null)
    const [allSubCategories, setAllSubCategories] = useState(null)

    const axiosInstance = useAxiosInstance()
    useEffect(() => {

        async function fetchAndSetCategories() {
            const { data } = await axiosInstance.get(MANAGE_CATEGORY_API)
            setAllCategories(data)
        }

        fetchAndSetCategories()

        if (categoryOrSubcategory === 'subcategory') {
            async function fetchAndSetSubcategories() {
                const { data } = await axiosInstance.get(SUBCATEGORIES_API)
                // console.log(data)
                const subCategoriesInCategory = data.filter(subCat => subCat.category === category)
                setAllSubCategories(subCategoriesInCategory)
            }

            fetchAndSetSubcategories()
        }

    }, [axiosInstance, category, categoryOrSubcategory])

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('price', price)
        itemData.append('isAvailable', isAvailable)
        itemData.append('isVisible', isVisible)
        itemData.append('image', image)
        itemData.append('categoryOrSubcategory', categoryOrSubcategory)
        if (categoryOrSubcategory === 'category') {
            itemData.append('category', category)
        }
        if (categoryOrSubcategory === 'subcategory') {
            itemData.append('subcategory', subcategory)
        }
        console.log(itemData)

        const response = await axiosInstance.post(MANAGE_ITEM_API, itemData)

        if (response) {
            setName('')
            setDescription('')
            setPrice(0)
            setIsAvailable(false)
            setIsVisible(false)
            setImage(null)

            setCategoryOrSubcategory('category')
            setCategory('')
            setSubcategory('')


            setShowCreateForm(false);
            setShowModal(false);
  }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`Item Name`}
                value={name}
                placeholder={`Enter Item Name`}
                setState={setName}
            />
            <LongTextInput
                label={`Item Description`}
                value={description}
                placeholder={`Enter Item Description`}
                setState={setDescription}
            />
            <NumberInput
                label={`Item Price`}
                value={price}
                placeholder={`Enter Item Price`}
                setState={setPrice}
            />
            <SwitchInput
                label={'Is Available'}
                toggleSwitch={() => setIsAvailable(prevState => !prevState)}
                checked={isAvailable}
            />

            <SwitchInput
                label={'Is Visible'}
                toggleSwitch={() => setIsVisible(prevState => !prevState)}
                checked={isVisible}
            />

            <ImageInput setState={setImage} allowCreateImage={true}>Upload Image</ImageInput>

            <RadioInputGroup radioGroupLabel={'Category or Subcategory'}>
                <RadioInput
                    label={'Category'}
                    value={'category'}
                    state={categoryOrSubcategory}
                    setState={setCategoryOrSubcategory}
                />
                <RadioInput
                    label={'Subcategory'}
                    value={'subcategory'}
                    state={categoryOrSubcategory}
                    setState={setCategoryOrSubcategory}
                />
            </RadioInputGroup>

            <SelectInput value={category} setState={setCategory}>
                <SelectOption optionValue={''} optionText={`Select Category`} />
                {allCategories && allCategories.map(cat => (
                    <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
                ))}
            </SelectInput>
            {
                (category.length > 0) && (categoryOrSubcategory === 'subcategory') &&
                <SelectInput value={subcategory} setState={setSubcategory}>
                    <SelectOption optionValue={''} optionText={`Select Subcategory`} />
                    {allSubCategories && allSubCategories.map(subCat => (
                        <SelectOption key={subCat._id} optionValue={subCat._id} optionText={subCat.name} />
                    ))}
                </SelectInput>
            }
            <FormSubmitButton text='Create Item' />
        </Form>
    )
}

export default CreateItem