import { useContext, useEffect, useState } from "react"
import { RiListSettingsLine } from "react-icons/ri"
import { useNavigate, useParams } from "react-router-dom"
import { MANAGE_CUSTOMER, ORDERS_API } from "../../../../../../Utilities/APIs/APIs"
import Modal from "../../../../../Partials/Elements/Modal/Modal"
import CRUDButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ViewOrder from "../../../../OrderScreens/ViewOrder"
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance"
import AppContext from "../../../../../../AppContext/AppContext"

function OrdersFromCustomer() {

    const [orders, setOrders] = useState(null)
    console.log(orders)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)

    const {employee} = useContext(AppContext)

    const {customerID} = useParams()
    const navigate = useNavigate()

    const axiosInstance = useAxiosInstance()

    useEffect(() => {


        async function fetchAndSetOrders() {
            const {data} = await axiosInstance.get(MANAGE_CUSTOMER + 'getSingleCustomerOrders/' + customerID,)
            setOrders(data)
        }
        fetchAndSetOrders()

    }, [axiosInstance, customerID])

    function handleClose() {
        setShowModal(false);
        setShowViewSection(false);
    }

    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Orders (${orders?.length} in total)`}</h1>
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th= 'Order ID' />
                        <CRUDth th= 'Status' />
                        <CRUDth th= 'Total' />
                        <CRUDth th= 'Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {orders?.map(order => (
                            <CRUDTableRow key={order._id}>
                                <ShortTextCell text={order._id} />
                                <ShortTextCell text={order.orderStatus} />
                                <ShortTextCell text={order.grandTotal} />
                                <td className="action_button_cell">
                                    <ViewButton
                                    setShowModal={setShowModal}
                                    setShowViewSection={setShowViewSection}
                                    targetID={order._id}
                                    setTargetID={setTargetID}
                                    />
                                    <CRUDButton handleClick={() => navigate('/order_items_from_order/' + order._id)}>
                                        <RiListSettingsLine />
                                    </CRUDButton>
                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal && 
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Order`)
                    }
                >
                    {
                        showViewSection &&
                            <ViewOrder
                                targetID={targetID}
                                employee={employee}
                            />
                    }    
                </Modal>
            }
        </>
    )
}

export default OrdersFromCustomer