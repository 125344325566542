import "./ImageCell.css";

function ImageCell({ imgSrc, imgAlt }) {
  return (
    <td>
      <img className="cell-image" src={imgSrc} alt={imgAlt} />
    </td>
  );
}

export default ImageCell;
