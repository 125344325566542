import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { BLOGS } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
function UpdateBlogPost({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [categories, setCategories] = useState("");
  const [authorImage, setAuthorImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        BLOGS + "getSingleBlog/" + targetID
      );
      console.log("hero data12345:", data);
      setTitle(data.title);
      setDescription(data.description);
      setAuthorName(data.authorName);
      setCategories(data.categories);
      setAuthorImage(data.authorImage);
      setCoverImage(data.coverImage);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("authorName", authorName);
    itemData.append("authorImage", authorImage);
    itemData.append("categories", categories);
    itemData.append("coverImage", coverImage);

    const response = await axiosInstance.patch(
      BLOGS + targetID,
      itemData,
      config
    );

    console.log(response);

    if (response.status === 200 || response.status === 201) {
      setTitle("");
      setDescription("");
      setAuthorName("");
      setCategories("");
      setAuthorImage(null);
      setCoverImage(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <ShortTextInput
        label={`Author Name`}
        value={authorName}
        placeholder={`Enter Name`}
        setState={setAuthorName}
      />
      <ShortTextInput
        label={`Category Name`}
        value={categories}
        placeholder={`Enter Category Name`}
        setState={setCategories}
      />
      <CustomEditor setState={setDescription} data={description || ""} />

      <ImageInput
        heading={"Upload Author Image"}
        state={authorImage}
        setState={setAuthorImage}
        fieldId={"authorImage"}
        allowUpdateImage={true}
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput>

      <ImageInput
        heading={"Upload Cover Image"}
        state={coverImage}
        setState={setCoverImage}
        fieldId={"coverImage"}
        allowUpdateImage={true}
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput>

      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default UpdateBlogPost;
