import { Link } from "react-router-dom";
import "./NavCard.css";

function NavCard({ children, cardName, navCardLink, ...rest }) {
  return (
    <div className="nav_card" {...rest}>
      <Link to={navCardLink}>
        <div className="nav_card_container">
          {children}
          <p className="nav_card_name" style={{ marginTop: "1rem" }}>
            {cardName}
          </p>
        </div>
      </Link>
    </div>
  );
}

export default NavCard;
