import { useEffect, useState } from "react";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import { Payment_detail_API } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";

function CreatePaymentDetailsForm({
  PaymentData,
  employee,
  targetID,
  setShowCreateForm,
  setShowModal,
}) {
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [number, setNumber] = useState("");
  const [paymentTypeSelect, setPaymentTypeSelect] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(Payment_detail_API + targetID);
      console.log("hero data12345:", data);
      setBankName(data.bankName);
      setNumber(data.number);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("bankName", bankName);
    itemData.append("branchName", branchName);
    itemData.append("holderName", holderName);
    itemData.append("number", number);
    itemData.append("paymentType", paymentTypeSelect);

    const response = await axiosInstance.post(
      Payment_detail_API,
      itemData,
      config
    );

    if (response) {
      setBankName("");
      setNumber("");

      setShowCreateForm(false);
      setShowModal(false);
    }
  }
  console.log("paymentTypeSelect", paymentTypeSelect)
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <label className="input_field_label caption bold">Pyment type</label>
      <SelectInput value={paymentTypeSelect} setState={setPaymentTypeSelect}>
        <SelectOption optionValue={""} optionText={"Select Pyment type"} />
        {PaymentData[0]?.paymentType === "MFS" && <SelectOption optionValue={"bank"} optionText={"Bank"} />}
        {PaymentData[0]?.paymentType === "bank" && <SelectOption optionValue={"MFS"} optionText={"MFS"} />}
      </SelectInput>

      <ShortTextInput
        label={`Bank name`}
        value={bankName}
        placeholder={`Enter Bank Name`}
        setState={setBankName}
      />
      <ShortTextInput
        label={`Branch Name`}
        value={branchName}
        placeholder={`Enter Branch Name`}
        setState={setBranchName}
      />
      <ShortTextInput
        label={`Bank Holder Name`}
        value={holderName}
        placeholder={`Enter Bank Holder Name`}
        setState={setHolderName}
      />

      <ShortTextInput
        label={`Number`}
        value={number}
        placeholder={`Enter Number`}
        setState={setNumber}
      />

      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreatePaymentDetailsForm;
