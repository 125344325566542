function FilterSelect({ filter, setFilter }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <select
        className=""
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{
          background: "transparent",
          color: "#fff",
          padding: "5px 15px",
          borderRadius: "7px",
        }}
      >
        <option value={"active"}>Active</option>
        <option value={"archive"}>Archived</option>
      </select>
    </div>
  );
}

export default FilterSelect;
