import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
function CreateHeroForm({
  employee,
  targetID,
  setShowCreateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("image", image);

    const response = await axiosInstance.post(
      HOME_API + "hero/",
      itemData,
      config
    );

    if (response.status === 200 || response.status === 201) {
      setTitle("");
      setDescription("");
      setImage(null);

      setShowCreateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      {/* <LongTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      /> */}
      <CustomEditor setState={setDescription} />

      <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowCreateImage={true}
      ></ImageInput>

      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateHeroForm;
