import { useState, useEffect } from "react"
import { SOCIAL_LINKS_API } from "../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"


function ViewSocialLinks({ targetID }) {

    const [socialLinks, setSocialLinks] = useState(null)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetSocialLinksInfo() {

            const { data } = await axiosInstance.get(SOCIAL_LINKS_API + 'getSingleSocialLink/' + targetID)
            setSocialLinks(data);
        }
        fetchAndSetSocialLinksInfo()
    }, [axiosInstance, targetID])

    return (
        <div className="crud_view_content">

            {socialLinks &&
                <>

                    <h1>Name</h1>
                    <p>{socialLinks?.name}</p>

                    <h1>Link</h1>
                    <p>{socialLinks?.link}</p>

                    
                    <h1>Visibility</h1>
                <p>{socialLinks?.isVisible === true ? 'TRUE' : 'FALSE'}</p>
                
                </>
            }
        </div>
    )
}

export default ViewSocialLinks