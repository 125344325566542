import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { CONTACT, MANAGE_EMPLOYEE_API } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewEmployee({ targetID }) {
  const [content, setContent] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        // CONTACT
        MANAGE_EMPLOYEE_API + "getSingleEmployee/" + targetID
      );
      console.log("team12345", data);
      setContent(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {content && (
        <>
          <Image imgLink={content?.dp} imgAlt={"Employ"} />
          <h1>Name</h1>
          <p>{content?.name}</p>

          <h1>Email</h1>
          <p>{content?.email}</p>

          <h1>Level</h1>
          <p>{content?.level}</p>
        </>
      )}
    </div>
  );
}

export default ViewEmployee;
