import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API, about_API } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewAbout({ targetID }) {
  const [content, setContent] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        about_API + "ourMission/getSingleOurMission/" + targetID
      );
      console.log("team12345", data);
      setContent(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {content && (
        <>
          <Image imgLink={content.image} imgAlt={"Team Image"} />

          <h1>Name</h1>
          <p>{content?.name}</p>

          <h1>Description</h1>
          <p>{content?.description}</p>

          <h1>Designation</h1>
          <p>{content?.designation}</p>
        </>
      )}
    </div>
  );
}

export default ViewAbout;
