import { useEffect, useState } from "react";
import { ALL_EVENTS_API, EVENTS_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateEvent({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longdescription, setLongDescription] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  // const [latitude, setLatitude] = useState("");
  // const [longitude, setLongitude] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");
  const [dateString, setDateString] = useState("");
  const [time, setTime] = useState("");
  const [participationTitle, setParticipationTitle] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [image, setImage] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(
        ALL_EVENTS_API + targetID
      );
      setTitle(data.title);
      setShortDescription(data.shortDescription);
      setLongDescription(data.longdescription);
      setCategory(data.category);
      setLocation(data.location);
      setGoogleMapLink(data.googleMapLink);
      // setLongitude(data.longitude);
      setDateString(data.dateString);
      setTime(data.time);
      setParticipationTitle(data.participationTitle);
      setBankName(data.bankName);
      setAccountHolderName(data.accountHolderName);
      setAccountNumber(data.accountNumber);
      setImage(data.image);
      setPrecedence(data.precedence);
    }
    fetchAndSetProductInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("shortDescription", shortDescription);
    itemData.append("longdescription", longdescription);
    itemData.append("category", category);
    itemData.append("location", location);
    itemData.append("googleMapLink", googleMapLink);
    // itemData.append("longitude", longitude);
    itemData.append("dateString", dateString);
    itemData.append("time", time);
    itemData.append("participationTitle", participationTitle);
    itemData.append("bankName", bankName);
    itemData.append("accountHolderName", accountHolderName);
    itemData.append("accountNumber", accountNumber);
    itemData.append("precedence", precedence);
    itemData.append("image", image);

    const response = await axiosInstance.patch(ALL_EVENTS_API + targetID, itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Short Description`}
        value={shortDescription}
        placeholder={`Enter Short Description`}
        setState={setShortDescription}
      />
      <ShortTextInput
        label={`Long Description`}
        value={longdescription}
        placeholder={`Enter Long Description`}
        setState={setLongDescription}
      />
      <ShortTextInput
        label={`Category`}
        value={category}
        placeholder={`Enter Category`}
        setState={setCategory}
      />
      <ShortTextInput
        label={`Location`}
        value={location}
        placeholder={`Enter Location`}
        setState={setLocation}
      />
      <ShortTextInput
        label={`Location Link`}
        value={googleMapLink}
        placeholder={`Enter Location Link`}
        setState={setGoogleMapLink}
      />
      {/* <ShortTextInput
        label={`Longitude`}
        value={longitude}
        placeholder={`Enter Longitude`}
        setState={setLongitude}
      /> */}
      <DateInput
        label={`dateString`}
        value={dateString}
        placeholder={`Enter Date`}
        setState={setDateString}
      />
      <ShortTextInput
        label={`time`}
        value={time}
        placeholder={`Enter Time`}
        setState={setTime}
      />
      <ShortTextInput
        label={`Participation Title`}
        value={participationTitle}
        placeholder={`Enter Participation Title`}
        setState={setParticipationTitle}
      />
      <ShortTextInput
        label={`Bank Name`}
        value={bankName}
        placeholder={`Enter Bank Name`}
        setState={setBankName}
      />
      <ShortTextInput
        label={`Account Holder Name`}
        value={accountHolderName}
        placeholder={`Enter Holder Name`}
        setState={setAccountHolderName}
      />
      <ShortTextInput
        label={`Account Number`}
        value={accountNumber}
        placeholder={`Enter Account Number`}
        setState={setAccountNumber}
      />
      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter precedence`}
        setState={setPrecedence}
      />

      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Card Image
      </ImageInput>
      <FormSubmitButton text="Update Event" />
    </Form>
  );
}

export default UpdateEvent;
