import { useState, useEffect } from "react"
import { IMAGE_URL, TESTIMONIALS_API } from "../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"

function ViewTestimonials({   targetID, employee}) {

    const [testimonial, setTestimonial] = useState(null)
  
    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetTestimonialInfo() {

            const { data } = await axiosInstance.get(TESTIMONIALS_API + 'getSingleTestimonials/' + targetID)
            setTestimonial(data);
        }
        fetchAndSetTestimonialInfo()
    }, [axiosInstance, targetID])

    return (
        <div className="crud_view_content">

            {testimonial &&
                <>
                   
                    <h1>Name</h1>
                    <p>{testimonial?.name}</p>

                <h1>Designation</h1>
                <p>{testimonial?.designation}</p>

                <h1>Testimonial</h1>
                <p>{testimonial?.testimonial}</p>

                <h1>Precedence</h1>
                <p>{testimonial?.precedence}</p>
                <>
                    <h1>Image</h1>
                    <img
                        src={IMAGE_URL + testimonial.image}
                        alt={testimonial.name ? testimonial.name : 'image'}
                    />
                </>

                </>
            }
        </div>
    )
}

export default ViewTestimonials