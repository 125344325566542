import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { BsCalendarEvent, BsCashStack } from "react-icons/bs";

export default function AllEvents() {
  return (
    <div>
      <NavCardList numOfCards={"two"}>
        <NavCard cardName={"Premium Event"} navCardLink="premiumEvents">
          <BsCashStack />
        </NavCard>

        <NavCard cardName={"Other Events"} navCardLink="freeEvents">
          <BsCalendarEvent />
        </NavCard>
      </NavCardList>
    </div>
  );
}
