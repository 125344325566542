import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
function UpdateRevenueGrowth({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [image, setImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        HOME_API + "revenueGrowth/getSingleRevenueGrowth/" + targetID
      );
      console.log("hero data12345:", data);
      setName(data.title);
      setValue(data.value);
      setImage(data.icon);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", name);
    itemData.append("value", value);
    itemData.append("image", image);

    const response = await axiosInstance.patch(
      HOME_API + "revenueGrowth/" + targetID,
      itemData,
      config
    );

    console.log(response);

    if (response.status === 200) {
      setName("");
      setValue("");
      setImage(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <LongTextInput
        label={`Description`}
        value={value}
        placeholder={`Enter Description`}
        setState={setValue}
      />

      <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowUpdateImage={true}
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput>

      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateRevenueGrowth;
