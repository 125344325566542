import { useEffect, useState } from "react";
import { APBC_GALLERY_IMAGE_API } from "../../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateAPBC_GalleryImage({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(
        APBC_GALLERY_IMAGE_API + "getSingleAPBCGalleryImage/" + targetID
      );
      setImage(data.image);
    }
    fetchAndSetProductInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);

    const response = await axiosInstance.patch(
      APBC_GALLERY_IMAGE_API + "by_params/" + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Update Card Image
      </ImageInput>
      <FormSubmitButton text="Update Gallery Image" />
    </Form>
  );
}

export default UpdateAPBC_GalleryImage;
