import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_CUSTOMER } from "../../../../../Utilities/APIs/APIs";

function ViewCustomer({
  //common props
  targetID,
  employee,
}) {
  const [customer, setCustomer] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetCustomer() {
      const { data } = await axiosInstance.get(
        MANAGE_CUSTOMER + "getSingleCustomer/" + targetID
      );

      setCustomer(data);

      console.log("data emploee", data);
    }

    fetchAndSetCustomer();
  }, [axiosInstance, targetID]);

  console.log(customer, "sdsdsd");
  return (
    <div className="crud_view_content">
      <h1>Full Name</h1>
      <p>{customer?.fullName}</p>
      <h1>Father Name</h1>
      <p>{customer?.fatherName}</p>
      <h1>Mother Name</h1>
      <p>{customer?.motherName}</p>
      <h1>Email</h1>
      <p>{customer?.emailAddress}</p>
      <h1>Phone</h1>
      <p>{customer?.phoneNumber}</p>
      <h1>Is Approved</h1>
      <p>{customer?.isApproved}</p>
      <h1>Is Verified</h1>
      <p>{customer?.isVerified ? "Verified" : "not Verified"}</p>
      <h1>Membership Status</h1>
      <p>{customer?.membershipStatus}</p>
      <h1>Occupation</h1>
      <p>{customer?.occupation}</p>
      <h1>Mbbs Institute</h1>
      <p>{customer?.mbbsInstitute}</p>
      <h1>Mbbs Year</h1>
      <p>{customer?.mbbsYear}</p>
      <h1>Permanent Address</h1>
      <p>{customer?.permanentAddress}</p>
      <h1>Present Position</h1>
      <p>{customer?.presentPosition}</p>
      <h1>Present Address</h1>
      <p>{customer?.presentAddress}</p>
      <h1>Post Year</h1>
      <p>{customer?.postYear}</p>
      <h1>Post Institute</h1>
      <p>{customer?.postInstitute}</p>
      <h1>Joined</h1>
      <p>{customer?.createdAt}</p>

      <h1>Attachment Of Certificate</h1>
      <figure style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={
            process.env.REACT_APP_SPACES_URL + customer?.attachmentOfCertificate
          }
          alt=""
        />
      </figure>

      <h1>Deposit Slip</h1>
      <figure style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={process.env.REACT_APP_SPACES_URL + customer?.depositSlip}
          alt=""
        />
      </figure>
    </div>
  );
}

export default ViewCustomer;
