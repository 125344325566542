import { useState, useEffect } from "react"
import { IMAGE_URL, MANAGE_CATEGORY_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"

function ViewCategory({   targetID, employee}) {

    const [category, setCateroy] = useState(null)
    console.log("🚀 ~ ViewCategory ~ category:", category)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetCategory() {

            const { data } = await axiosInstance.get(MANAGE_CATEGORY_API + targetID)
            setCateroy(data);
        }
        fetchAndSetCategory()
    }, [axiosInstance, targetID])

    return (
        <div className="crud_view_content">

            {category &&
                <>
                   
                    <h1>Name</h1>
                    <p>{category?.name}</p>

                    <h1>Description</h1>
                    <p>{category?.description}</p>
                <>
                    <h1>Image</h1>
                    <img
                        src={IMAGE_URL + category.image}
                        alt={category.name ? category.name : 'image'}
                    />
                </>

                </>
            }
        </div>
    )
}

export default ViewCategory