import { useState } from "react";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import {
  APBC_GALLERY_IMAGE_API,
  GALLERY_IMAGE_API,
} from "../../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";

function CreateAPBC_GalleryImage({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  galleryId,
}) {
  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("galleryId", galleryId);
    itemData.append("image", image);

    const response = await axiosInstance.post(APBC_GALLERY_IMAGE_API, itemData);
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Gallery Image
      </ImageInput>
      <FormSubmitButton text="Create Gallery Image" />
    </Form>
  );
}

export default CreateAPBC_GalleryImage;
