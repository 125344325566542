import { useContext, useEffect, useState } from "react"
import AppContext from "../../../../../AppContext/AppContext"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import { NAV_COLLECTIONS_API } from "../../../../../Utilities/APIs/APIs"
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import Modal from "../../../../Partials/Elements/Modal/Modal"
import CreateNavCollections from "./CreateNavCollections"
import ViewNavCollections from "./ViewNavCollections"
import UpdateNavCollections from "./UpdateNavCollections"
import DeleteItem from "../../../../Partials/Layouts/DeleteItem/DeleteItem"



function NavCollections() {

    const [navCollections, setNavCollections] = useState(null)
    // console.log("🚀 ~ NavCollections ~ navCollections:", navCollections)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)
    const { employee } = useContext(AppContext)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetNavCollections() {
            const { data } = await axiosInstance.get(NAV_COLLECTIONS_API)
            setNavCollections(data)
        }
        fetchAndSetNavCollections()


    }, [toggleFetch, axiosInstance])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }


    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Nav Collections (${navCollections?.length} in total)`}</h1>
                    <CreateButton
                        screenTopicSingular='Nav Collection'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    />
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Description' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {navCollections?.map(collection => (
                            <CRUDTableRow key={collection?._id}>
                                <ShortTextCell text={collection?.collectionID?.name} />
                                <ShortTextCell text={collection?.precedence} />

                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={collection._id}
                                        setTargetID={setTargetID}
                                    />
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={collection._id}
                                        setTargetID={setTargetID}
                                    />
                                    <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={collection?._id}
                                        setTargetID={setTargetID}
                                    />

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Nav Collection`) ||
                        (showUpdateForm && `Update Nav Collection`) ||
                        (showDeleteSection && `Delete Nav Collection`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateNavCollections
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showUpdateForm &&
                        < UpdateNavCollections
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }


                    {
                        showDeleteSection &&
                        <DeleteItem
                            api={NAV_COLLECTIONS_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Nav Collection`)
                    }
                >
                    <ViewNavCollections
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default NavCollections