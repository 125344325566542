import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewBenefitDetail({ targetID }) {
  const [benefits, setBenefits] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        HOME_API + "benefits/getSingleBenefit/" + targetID
      );
      console.log("team12345", data);
      setBenefits(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {benefits && (
        <>
          <Image
            imgLink={process.env.REACT_APP_SPACES_URL + benefits.icon}
            imgAlt={"Team Image"}
          />

          <h1>Name</h1>
          <p>{benefits?.title}</p>

          <h1>Description</h1>
          <p>{benefits?.description}</p>

          <h1>Benefit List</h1>
          <ul
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              color: "#000",
              width: "100%",
              padding: "3px 10px",
            }}
          >
            {benefits?.benefitsLists.map((item, index) => (
              <li>
                {index + 1}. {item}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default ViewBenefitDetail;
