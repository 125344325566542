import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import { Payment_detail_API } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function UpdatePaymentDetailsForm({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [number, setNumber] = useState("");
  const [paymentTypeSelect, setPaymentTypeSelect] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(Payment_detail_API + targetID);
      setBankName(data.bankName);
      setBranchName(data?.branchName)
      setHolderName(data?.holderName)
      setNumber(data.number);
      setPaymentTypeSelect(data.paymentType);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("bankName", bankName);
    itemData.append("branchName", branchName);
    itemData.append("holderName", holderName);
    itemData.append("number", number);
    itemData.append("paymentType", paymentTypeSelect);

    const response = await axiosInstance.patch(
      Payment_detail_API + targetID,
      itemData,
      config
    );

    if (response) {
      setBankName("");
      setNumber("");

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <label className="input_field_label caption bold">Pyment type</label>
      <SelectInput  value={paymentTypeSelect} setState={setPaymentTypeSelect}>
        <SelectOption optionValue={""} optionText={"Select Pyment type"} />
        <SelectOption optionValue={"bank"} optionText={"Bank"} />
        <SelectOption optionValue={"MFS"} optionText={"MFS"} />
      </SelectInput>
      <ShortTextInput
        label={`Bank name`}
        value={bankName}
        placeholder={`Enter Bank Name`}
        setState={setBankName}
      />
     <ShortTextInput
        label={`Branch Name`}
        value={branchName}
        placeholder={`Enter Branch Name`}
        setState={setBranchName}
      />
      <ShortTextInput
        label={`Bank Holder Name`}
        value={holderName}
        placeholder={`Enter Bank Holder Name`}
        setState={setHolderName}
      />

      <ShortTextInput
        label={`Number`}
        value={number}
        placeholder={`Enter Number`}
        setState={setNumber}
      />

      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdatePaymentDetailsForm;
