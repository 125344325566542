import { useContext, useEffect, useState } from "react";
import { HiBadgeCheck } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../../AppContext/AppContext";
import {
  APBC_Events_API,
  APBC_Events_Ticket_API,
  EVENTS_API,
} from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import ViewAPBCTicket from "./ViewAPBCTicket";
import APBCApproveItem from "./APBCApproveItem";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
// import ViewEvent from "./ViewEvent";

function APBCTicket() {
  const { eventId } = useParams();
  const [tickets, setTickets] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showApprove, setShowApprove] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [emailAddress, setEmailAddress] = useState(null);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetFeaturedItems() {
      const { data } = await axiosInstance.get(
        APBC_Events_API + "ticket/getAllTicket_by_eventId/" + eventId
      );

      setTickets(data);
    }
    fetchAndSetFeaturedItems();
  }, [toggleFetch, axiosInstance, eventId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Event Ticket (${tickets?.length} in total)`}</h1>
          {/* <CreateButton
            screenTopicSingular="Event"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          /> */}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="No" />
            <CRUDth th="Full name" />
            {/* <CRUDth th="Short Description" />
            <CRUDth th="Long Description" /> */}
            <CRUDth th="Email" />
            <CRUDth th="phoneNumber" />
            <CRUDth th="Status" />
            <CRUDth th="Action" />
          </CRUDTableHeader>
          <tbody>
            {tickets?.map((ticketItem, index) => (
              <CRUDTableRow key={ticketItem._id}>
                <ShortTextCell text={index + 1} />
                <ShortTextCell text={ticketItem?.fullName} />
                <ShortTextCell text={ticketItem?.emailAddress} />
                <ShortTextCell text={ticketItem?.phoneNumber} />
                <ShortTextCell text={ticketItem?.isApprove} />
                <div className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={ticketItem._id}
                    setTargetID={setTargetID}
                  />
                  {ticketItem.isApprove === "Pending" && (
                    <CRUDButton
                      handleClick={() => {
                        setShowModal(true);
                        setShowApprove(true);
                        setTargetID(ticketItem._id);
                        setEmailAddress(ticketItem.emailAddress);
                      }}
                    >
                      <HiBadgeCheck />
                    </CRUDButton>
                  )}

                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={ticketItem._id}
                    setTargetID={setTargetID}
                  />
                </div>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Featured Item`) ||
            (showUpdateForm && `Update Featured Item`) ||
            (showDeleteSection && `Delete Featured Item`)
          }
        >
          {/* {showCreateForm && (
            <CreateEvent
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )} */}
          {/* {showUpdateForm && (
            <UpdateEvent
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )} */}

          {showDeleteSection && (
            <DeleteItem
              api={APBC_Events_Ticket_API + "delete_ticket/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
          {showApprove && (
            <APBCApproveItem
              api={EVENTS_API + "/ticket/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
              email={emailAddress}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Event Item`}
        >
          <ViewAPBCTicket targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default APBCTicket;
