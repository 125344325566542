import { useEffect, useState } from "react";
import { EVENTS_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Image from "../../../Partials/Elements/Image/Image";
import { BsFilePdfFill } from "react-icons/bs";

function ViewTicket({ targetID }) {
  const [ticketInfo, setTicketInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetGalleryIngo() {
      const { data } = await axiosInstance.get(
        EVENTS_API + "/ticket/getSingleTicket/" + targetID
      );
      setTicketInfo(data);
    }
    fetchAndsetGalleryIngo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {ticketInfo && (
        <>
          <h1>Deposit Slip</h1>
          <Image
            imgLink={ticketInfo?.depositSlip}
            imgAlt={"Card Front Image"}
          />

          <h1>Abstract</h1>
          <span style={{ marginBottom: "12px" }}>
            <a href={ticketInfo?.abstract}>
              <BsFilePdfFill />
              View PDF
            </a>
          </span>

          <h1>Full Name</h1>
          <p>{ticketInfo?.fullName}</p>

          <h1>Father Name</h1>
          <p>{ticketInfo?.fatherName}</p>
          <h1>Mother Name</h1>
          <p>{ticketInfo?.motherName}</p>
          <h1>Phone Number</h1>
          <p>{ticketInfo?.phoneNumber}</p>
          <h1>Email Address</h1>
          <p>{ticketInfo?.emailAddress}</p>

          <h1> Submit Date</h1>
          <p>{new Date(ticketInfo?.createdAt).toDateString()}</p>

          <h1>BMDC Reg No</h1>
          <p>{ticketInfo?.bmdcRegNo}</p>
          <h1>Date Of Birth</h1>
          <p>{new Date(ticketInfo?.dateOfBirth).toDateString()}</p>
          <h1>Blood Group</h1>
          <p>{ticketInfo?.bloodGroup}</p>
          <h1>Gender</h1>
          <p>{ticketInfo?.gender}</p>
          <h1>NID</h1>
          <p>{ticketInfo?.nid}</p>
          {/* <h1>Nationality</h1>
          <p>{ticketInfo?.nationality}</p> */}
        </>
      )}
    </div>
  );
}

export default ViewTicket;
