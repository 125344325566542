import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APBC_GALLERY_IMAGE_API } from "../../../../../Utilities/APIs/APIs";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import CreateAPBC_GalleryImage from "./APBC_form/CreateAPBC_GalleryImage";
import UpdateAPBC_GalleryImage from "./APBC_form/UpdateAPBC_GalleryImage";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ImageCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import DeleteItem from "../../../../Partials/Layouts/DeleteItem/DeleteItem";
import AppContext from "../../../../../AppContext/AppContext";

function APBC_GalleryImage() {
  const { galleryId } = useParams();

  const [showViewSection, setShowViewSection] = useState("");
  const [images, setImages] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetStreets() {
      const { data } = await axiosInstance.get(
        APBC_GALLERY_IMAGE_API + "by_event_id/" + galleryId
      );
      setImages(data);

      console.log("apbc gallery image :", data);
    }
    fetchAndSetStreets();
  }, [axiosInstance, toggleFetch, galleryId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">
            Gallery Images ({images?.length} in total)
          </h1>

          <CreateButton
            screenTopicSingular={"Gallery Image"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Images" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {images &&
              images?.map((image) => (
                <CRUDTableRow key={image._id}>
                  <ImageCell
                    imgSrc={process.env.REACT_APP_SPACES_URL + image?.image}
                    alt={"team member"}
                  />

                  <td className="action_button_cell">
                    {/* <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    /> */}
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    />
                    <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    />
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Gallery`) ||
            (showUpdateForm && `Update Street `) ||
            (showDeleteSection && `Delete Street`)
          }
        >
          {showCreateForm && (
            <CreateAPBC_GalleryImage
              employee={employee}
              galleryId={galleryId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateAPBC_GalleryImage
              employee={employee}
              galleryId={galleryId}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={APBC_GALLERY_IMAGE_API + "by_params/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default APBC_GalleryImage;
