import { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { ALL_EVENTS_API } from "../../../../../Utilities/APIs/APIs";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import DateInput from "../../../../Partials/Layouts/Forms/FormInputs/DateInput";
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateEvent({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  storeId,
}) {
  const axiosInstance = useAxiosInstance();

  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");

  const [category, setCategory] = useState("free");
  const [location, setLocation] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");

  const [dateString, setDateString] = useState("");
  const [time, setTime] = useState("");

  const [precedence, setPrecedence] = useState(0);
  const [image, setImage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("shortDescription", shortDescription);
    itemData.append("longDescription", longDescription);

    itemData.append("category", category);
    itemData.append("location", location);
    itemData.append("googleMapLink", googleMapLink);

    itemData.append("dateString", dateString);
    itemData.append("time", time);
    itemData.append("precedence", precedence);
    itemData.append("image", image);

    const { data } = await axiosInstance.post(ALL_EVENTS_API, itemData);
    console.log(data);
    if (data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  // console.log("image is", image);
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Short Description`}
        value={shortDescription}
        placeholder={`Enter Short Description`}
        setState={setShortDescription}
      />
      <ShortTextInput
        label={`Long Description`}
        value={longDescription}
        placeholder={`Enter Long Description`}
        setState={setLongDescription}
      />

      <ShortTextInput
        label={`Location`}
        value={location}
        placeholder={`Enter Location`}
        setState={setLocation}
      />
      <ShortTextInput
        label={`Location Link`}
        value={googleMapLink}
        placeholder={`Enter Location Link`}
        setState={setGoogleMapLink}
      />

      <DateInput
        label={`dateString`}
        value={dateString}
        placeholder={`Enter Date`}
        setState={setDateString}
      />
      <ShortTextInput
        label={`time`}
        value={time}
        placeholder={`Enter Time`}
        setState={setTime}
      />

      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter precedence`}
        setState={setPrecedence}
      />

      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Card Image
      </ImageInput>
      <FormSubmitButton text="Create Event" />
    </Form>
  );
}

export default CreateEvent;
